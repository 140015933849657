import { defaultLicensee } from 'constants/constants';
import { useEffect, useState } from 'react';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import { CompanyModel, LicenseeModel } from 'services/EpdClient';

export const useCompany = () => {
  const [companyId, setCompanyId] = useState<string>();
  const [company, setCompany] = useState<CompanyModel>();
  const [companyAccountId, setCompanyAccountId] = useState<string>();
  const [licensee, setLicensee] = useState<LicenseeModel>();

  const fetchCompanyAndLicensees = async (newCompanyId?: string) => {
    await CompanyService.getCompany(newCompanyId || '')
      .then((newCompany: CompanyModel) => {
        changeCompanyAccount(newCompany.accounts?.[0]?.id || '');
        setCompanyId(newCompany?.id || '');
        window.sessionStorage.setItem('companyId', newCompany?.id || '');
        setCompany(newCompany);
        fetchLicensee(newCompany?.id);
      })
      .catch((error) => {
        setDefaultOrFirstCompany();
      });
  };

  const fetchLicensee = async (newCompanyId?: string) => {
    const newLicensees = await CompanyService.getCompanyLicensees(newCompanyId);
    setLicensee(newLicensees?.[0] ?? defaultLicensee);
  };

  const cleanSessionStorage = () => {
    window.sessionStorage.setItem('companyAccountId', '');
    window.sessionStorage.setItem('companyId', '');
  };

  const setDefaultCompany = () => {
    cleanSessionStorage();
    setCompanyId('');
    setCompany(undefined);
    changeCompanyAccount('');
    setLicensee(defaultLicensee);
  };

  const setDefaultOrFirstCompany = async () => {
    cleanSessionStorage();
    const companiesResult = await CompanyService.getCompanies();
    const firstCompanyId = companiesResult?.[0]?.id || '';
    if (firstCompanyId) {
      changeCompany(firstCompanyId);
    } else {
      setDefaultCompany();
    }
  };

  const changeCompany = (newCompanyId?: string) => {
    if (!newCompanyId || newCompanyId === '') return;

    if (newCompanyId !== companyId) {
      AuthService.updateUserLatestLoginCompanyId({ companyId: newCompanyId });
    }

    fetchCompanyAndLicensees(newCompanyId);
  };

  const changeCompanyAccount = (newAccountId?: string) => {
    window.sessionStorage.setItem('companyAccountId', newAccountId || '');
    setCompanyAccountId(newAccountId || '');
  };

  const refreshCompany = () => {
    if (!companyId || companyId === '') {
      if (!AuthService.isLoggedin()) {
        return;
      }
      const sessionCompanyId = window.sessionStorage.getItem('companyId') || '';

      if (sessionCompanyId) {
        setCompanyAccountId(window.sessionStorage.getItem('companyAccountId') || '');
        changeCompany(sessionCompanyId);
      } else {
        setDefaultOrFirstCompany();
      }
    }
  };

  return {
    companyId,
    company,
    companyAccountId,
    licensee,
    changeCompany,
    changeCompanyAccount,
    setDefaultOrFirstCompany,
    setDefaultCompany,
    fetchCompanyAndLicensees,
    refreshCompany,
  };
};
