import { useState } from 'react';
import { EPDModel, FlowModel, FlowPropertyModel, MaterialPropertyModel } from 'services/EpdClient';

import EditMachineReadableDialog from '../../dialogs/EditMachineReadableDialog';
import EpdDetailsGrid from '../EpdDetailsGrid';
import MachineReadableEPDKey from '../MachineReadableEPDKey';

type TProps = {
  epd: EPDModel;
  onChangeEpd: any;
  isReadOnly: boolean;
  isConstruction?: boolean;
  flowProperties: FlowPropertyModel[];
  materialProperties: MaterialPropertyModel[];
};

const MachineReadable = ({ epd, onChangeEpd, isReadOnly, isConstruction, flowProperties, materialProperties }: TProps) => {
  const [isMachineReadableEditOpened, setIsMachineReadableOpened] = useState<boolean>(false);

  const renderReferenceFlowData = (productFlow: FlowModel | undefined) => {
    const flowPropertyName = flowProperties.find((x) => x.id == productFlow?.flowPropertyItem?.flowPropertyId)?.name;
    const conversionFactor = productFlow?.flowPropertyItem?.conversionFactor;
    if (!productFlow?.name || !flowPropertyName || !conversionFactor) {
      return null;
    }
    return `${productFlow?.name}<br />Unit: ${flowPropertyName}, Value: ${conversionFactor}`;
  };

  return (
    <>
      <EpdDetailsGrid
        headerLabel={'Information for machine-readable EPDs'}
        isReadOnly={isReadOnly}
        data={[
          { key: MachineReadableEPDKey.GTIN, value: epd.gtin },
          {
            key: MachineReadableEPDKey.REFERENCE_FLOWS,
            value: renderReferenceFlowData(epd.productFlow),
            isHtml: true,
            required: isConstruction,
          },
          {
            key: MachineReadableEPDKey.MATERIAL_PROPERTIES,
            value: epd.productFlow?.materialPropertyItems
              ?.map(
                (x) =>
                  `${(epd.productFlow?.materialPropertyItems?.length || 0) > 1 ? '<li>' : ''}Property (Unit): ${
                    flowProperties.find((y) => y.id == x.materialPropertyId)?.name
                  }, Value: ${x.value}`
              )
              .join(''),
            isHtml: true,
          },
          { key: MachineReadableEPDKey.GEOGRAPHICAL_SCOPE_DESCRIPTION, value: epd.geographicalDescription },
          {
            key: MachineReadableEPDKey.TECHNOLOGY_DESCRIPTION,
            value: epd.technologyDescription,
            required: isConstruction,
          },
          { key: MachineReadableEPDKey.TECHNICAL_PURPOSE, value: epd.technicalPurpose, required: isConstruction },
          { key: MachineReadableEPDKey.SUBTYPE, value: epd.subtype },
          {
            key: MachineReadableEPDKey.DATA_SOURCES,
            value: epd.epdDataSources
              ?.map(
                (x) =>
                  `${(epd.epdDataSources?.length || 0) > 1 ? '<li>' : ''}${x.dataSource.name}, ${x.dataSourceVersion.name}`
              )
              .join(''),
            isHtml: true,
            required: isConstruction,
          },
          { key: MachineReadableEPDKey.USE_ADVICE, value: epd.useAdvice, required: isConstruction },
        ]}
        onEdit={() => setIsMachineReadableOpened(true)}
      />
      <EditMachineReadableDialog
        isOpened={isMachineReadableEditOpened}
        isConstruction={isConstruction}
        epd={epd}
        onHide={() => setIsMachineReadableOpened(false)}
        onChangeEpd={onChangeEpd}
        flowProperties={flowProperties}
        materialProperties={materialProperties}
      />
    </>
  );
};

export default MachineReadable;
