import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ElectricityEnum } from 'services/EpdClient';
import { useUpdateEpdElectricity } from 'services/api/mutations';
import { useElectricity } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';
import { ElectricityDataModel, EnergySourceModel } from 'types/types';

import { cellNumberEditor, cellTextEditor } from '../GridCellEditors';
import { mandatoryBodyTemplate } from '../GridService';
import { DataTableStyled, Placeholder } from '../StyledGrid';
import { isPositiveDecimal } from 'util/utils';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';

type TRow = {
  key: number;
  field: ElectricityEnum;
  fieldName: string;
  source: string | undefined;
  value: string | number | EnergySourceModel[] | null | undefined;
};

type TProps = {
  epdVersionId: string;
  errors?: any;
};

const ElectricityGrid = ({ epdVersionId, errors }: TProps) => {
  const electricityTable = useRef<any>(null);
  const { t } = useTranslation();
  const electricityData = useElectricity(epdVersionId).data;
  const updateElectricityMutation = useUpdateEpdElectricity();
  const isReadOnly = useIsReadOnlyMode();

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  // ToDo: refactor this.
  // Primereact Datatable doesn't support both rowspan and colspan column at the same time for content rows.
  // Rowspan is configured in Datatable, that why colspan is set on every rendering of the Electricity table
  useEffect(() => {
    if (electricityTable.current) {
      const table = electricityTable.current.getTable();
      const tbody = table.getElementsByTagName('tbody');
      const rows = tbody[0].getElementsByTagName('tr');
      if (rows.length >= 13) {
        setColSpan(rows, 0);
        setColSpan(rows, rows.length - 1);
      }
    }
  });

  const setColSpan = (rows: any, rowIndex: number) => {
    const cells = rows[rowIndex].getElementsByTagName('td');
    cells[1].style = 'display: none';
    cells[cells.length - 1].colSpan = 2;
  };

  const getColumnsData = (data: ElectricityDataModel | undefined) => {
    let index = 0;
    const columns = [
      {
        key: index,
        field: ElectricityEnum.ElectricityMix,
        fieldName: 'Electricity mix',
        value: data?.electricityMix,
      },
    ] as TRow[];

    electricityData?.energySourceModel?.forEach((item) => {
      columns.push({
        key: ++index,
        field: ElectricityEnum.EnergySource,
        fieldName: 'Energy sources',
        source: t(`epdEnergySource.${item.source}`),
        value: item.value?.toString(),
      });
    });

    columns.push({
      key: ++index,
      field: ElectricityEnum.ClimateImpact,
      fieldName: 'GWP-GHG intensity (kg CO2 eq./tonne):',
      value: data?.climateImpact,
    } as TRow);

    return columns;
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    const newData = { ...electricityData };

    switch (rowData.field) {
      case ElectricityEnum.ElectricityMix:
        rowData[field] = newValue;
        newData.electricityMix = newValue;

        updateElectricityMutation.mutate(newData);

        break;
      case ElectricityEnum.EnergySource:
        if (isPositiveDecimal(newValue)) {
          rowData[field] = newValue;
          const source = newData.energySourceModel?.find(
            (item: EnergySourceModel) => t(`epdEnergySource.${item.source}`) === rowData.source
          );
          if (source) {
            if (newValue > 100) {
              source.value = 100;
            } else {
              source.value = newValue;
            }
          }

          updateElectricityMutation.mutate(newData);
        }
        break;
      case ElectricityEnum.ClimateImpact:
        if (isPositiveDecimal(newValue)) {
          rowData[field] = newValue;
          newData.climateImpact = newValue;

          updateElectricityMutation.mutate(newData);
        } else {
          event.preventDefault();
        }

        break;
    }
  };

  const cellEditor = (options: ColumnEditorOptions) => {
    switch (options.rowData.field) {
      case ElectricityEnum.ElectricityMix:
        return cellTextEditor(options);
      case ElectricityEnum.EnergySource:
      case ElectricityEnum.ClimateImpact:
        return cellNumberEditor(options);
    }
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">
        Electricity used in the manufacturing process in A3
      </HeaderCaptionSemiBold>
    </div>
  );

  const valueBodyTemplate = (rowData: TRow) => {
    switch (rowData.field) {
      case ElectricityEnum.EnergySource:
        const sourceValue = rowData.value as number | undefined;
        return sourceValue ? `${sourceValue}%` : <Placeholder>Type value %</Placeholder>;
      case ElectricityEnum.ClimateImpact:
        const numberValue = rowData.value as number | undefined;
        return numberValue ? `${numberValue} kg CO2 eq./tonne` : <Placeholder>Type value, kg CO2 eq./tonne</Placeholder>;
      default:
        const stringValue = rowData.value as string | undefined;
        return stringValue && stringValue?.length > 0 ? stringValue : <Placeholder>Type value</Placeholder>;
    }
  };

  const mandatoryCellTemplate = (value: any, options: ColumnBodyOptions) => {
    return mandatoryBodyTemplate((value as any)?.[options?.field], errors?.[value?.field]);
  };

  return (
    <DataTableStyled
      header={header}
      value={getColumnsData(electricityData)}
      dataKey="key"
      showGridlines
      editMode="cell"
      rowGroupMode="rowspan"
      groupRowsBy="fieldName"
      style={{ marginTop: '16px' }}
      ref={electricityTable}
      rowClassName={rowClassName}
    >
      <Column
        field="fieldName"
        headerStyle={{ display: 'none' }}
        style={{ verticalAlign: 'top' }}
        body={mandatoryCellTemplate}
      />
      <Column field="source" headerStyle={{ display: 'none' }} style={{ width: '36%' }} />
      <Column
        field="value"
        body={valueBodyTemplate}
        editor={isReadOnly ? undefined : cellEditor}
        onCellEditComplete={onCellEditComplete}
        headerStyle={{ display: 'none' }}
        style={{ width: '36%' }}
      />
    </DataTableStyled>
  );
};

export default ElectricityGrid;
