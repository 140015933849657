import PrimeCheckbox from 'components/form/PrimeCheckbox';
import { PrimaryButton, SecondaryButton } from 'components/v2/buttons';
import { confirmAlert } from 'components/v2/confirm-dialog/ConfirmAlert';
import InputFormField from 'components/v2/form/InputFormField';
import SelectDataField from 'components/v2/form/SelectDataField';
import PcrSelector from 'components/v2/pcr-selector/PcrSelector';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  CopyEpdFormikModel,
  CopyEpdV2Model,
  DataSourceModel,
  EPDDeveloperModel,
  EPDVerifierModel,
  EpdCopyGroupWizardModel,
  EpdMachineReadableEnum,
  EpdProductInformationEnum,
  MembershipModel,
  PCRModel,
  PreverifiedToolModel,
  PreverifiedToolVersionModel,
  VerificationType,
} from 'services/EpdClient';
import styled from 'styled-components';
import { StyledForm } from 'styles/Styles.styled';
import { CheckBoxGroup, CheckBoxPanel, FieldPanelInput } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';
import * as Yup from 'yup';

const WizardStep1: React.FunctionComponent<{
  groupData?: EpdCopyGroupWizardModel;
  copyGroupValues?: CopyEpdFormikModel;
  activeStep: number;
  companyName?: string;
  previousStep: number;
  pcr: PCRModel | undefined;
  setPcr: any;
  setCopyGroupValues: any;
  handleSubmit: any;
  handleGetSourceEPDDataToCopyAll: any;
}> = ({
  groupData,
  copyGroupValues,
  activeStep,
  companyName,
  previousStep,
  pcr,
  setPcr,
  setCopyGroupValues,
  handleSubmit,
  handleGetSourceEPDDataToCopyAll,
}) => {
  const { t } = useTranslation();
  const initialValues = {
    sourceEpdVersionId: groupData?.sourceEpdVersionId,
    edpCopyGroupName: groupData?.name ?? '',
    collectionId: groupData?.collectionId,
    practitionersIds: groupData?.practitionersIds ?? [],
    verifierId: groupData?.verifierId,
    singleCopy: false,
    copyAllDataset: groupData?.copyAllData,
    count: groupData?.count,
    verificationType:
      groupData?.epdCopyGroupDataSources?.verificationTypeDataSource?.length === 1
        ? groupData.epdCopyGroupDataSources.verificationTypeDataSource[0]
        : groupData?.verificationType,
    preverifiedToolId: groupData?.preverifiedToolId,
    preverifiedToolVersionId: groupData?.preverifiedToolVersionId,
    keywordsList: groupData?.selectedKeywordsList ?? [],
    productInformationSectionsList: groupData?.selectedProductInformationSectionsList ?? [],
    machineReadableSectionsList: groupData?.selectedMachineReadableSectionsList ?? [],
    isSectorEPD: groupData?.isSectorEPD ?? false,
  };

  const getDevelopersDataSourceOptions = (values: MembershipModel[] | undefined) => {
    const grouped = Object.entries(_.groupBy(values, (v) => v.userId));
    const result = grouped.map(([userId, value]) => {
      const roles = value.map((p) => p.roleName);
      return {
        value: userId,
        label: `${values?.find((membership: MembershipModel) => membership.userId === userId)?.userName} (${roles
          .sort()
          .join(', ')})`,
      } as Option;
    });

    return result;
  };

  const [developersDataSource, setDevelopersDataSource] = useState<Option[]>(
    getDevelopersDataSourceOptions(groupData?.developersSource)
  );

  useEffect(() => {
    setDevelopersDataSource(getDevelopersDataSourceOptions(groupData?.developersSource));
  }, [groupData?.developersSource]);

  const productInformationSectionDataSource =
    groupData?.epdCopyGroupDataSources?.productInformationSectionDataSource.map((value: EpdProductInformationEnum) => {
      return {
        value: value,
        label: t(`epdSectorsEnum.${value}`),
      } as Option;
    }) ?? [];

  const machineReadableSectionDataSource =
    groupData?.epdCopyGroupDataSources?.machineReadableSectionDataSource.map((value: EpdMachineReadableEnum) => {
      return {
        value: value,
        label: t(`epdSectorsEnum.${value}`),
      } as Option;
    }) ?? [];

  const handleEPDCopyGroupCreate = async (values: any) => {
    const sourceEPD = groupData?.epdCopyGroupDataSources?.epdsToCopy.find(
      (epd: DataSourceModel) => epd.id === values.sourceEpdVersionId
    );
    confirmAlert({
      caption: values.singleCopy
        ? 'You have requested a single EPD copy creation without a group'
        : `You have chosen ${sourceEPD?.name} as source EPD dataset.`,
      description: values.singleCopy ? (
        <div>
          Once you click the button "Submit" will be re-directed to the main EPD creation flow. Selected source EPD and EPD
          dataset will be saved and applied to a new EPD copy.
        </div>
      ) : (
        <div>
          Please note, once you click the button "Submit" you will not be able to make changes to Step 1 - Select source EPD.
        </div>
      ),
      acceptLabel: 'Submit',
      rejectLabel: 'Cancel',
      onConfirm: async () => {
        await handleSubmit({
          ...values,
          pcrId: pcr?.id,
        } as CopyEpdV2Model);
      },
      onReject: () => {},
    });
  };

  const handleChangeCopyAllDataSet = async (e: any, values: any, setValues: any) => {
    if (e.target.name === 'sourceEpdVersionId' && values.copyAllDataset === true && e.target.value === '') {
      setValues({ ...values, sourceEpdVersionId: '', copyAllDataset: false });
    } else {
      const sourceEPDId = e.target.name === 'copyAllDataset' ? values.sourceEpdVersionId : e.target.value;
      if (
        (e.target.name === 'copyAllDataset' && e.target.checked === true && sourceEPDId) ||
        (e.target.name === 'sourceEpdVersionId' && values.copyAllDataset === true && sourceEPDId)
      ) {
        try {
          const sourceEpdData = await handleGetSourceEPDDataToCopyAll(sourceEPDId);
          setValues({
            ...values,
            sourceEpdVersionId: e.target.name === 'sourceEpdVersionId' ? sourceEPDId : values.sourceEpdVersionId,
            copyAllDataset: e.target.name === 'copyAllDataset' ? e.target.checked : values.copyAllDataset,
            practitionersIds:
              sourceEpdData?.practitionersIds.filter((x: any) =>
                groupData?.developersSource?.map((m) => m.userId).includes(x)
              ) ?? [],
            verifierId: groupData?.verifiersSource?.find((x) => x.contactUserId === sourceEpdData?.verifierId)
              ? sourceEpdData?.verifierId
              : null,
            verificationType: groupData?.epdCopyGroupDataSources?.verificationTypeDataSource?.find(
              (x) => x === sourceEpdData?.verificationType
            )
              ? sourceEpdData?.verificationType
              : null,
            preverifiedToolId: sourceEpdData?.preverifiedToolId,
            preverifiedToolVersionId: sourceEpdData?.preverifiedToolVersionId,
            keywordsList: sourceEpdData?.selectedKeywordsList ?? [],
            isSectorEPD: sourceEpdData?.isSectorEPD,
            productInformationSectionsList: productInformationSectionDataSource.map((option: Option) => option.value),
            machineReadableSectionsList: machineReadableSectionDataSource.map((option: Option) => option.value),
          });

          setPcr(sourceEpdData?.pcr);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleChangeSingleCopy = async (e: any, values: any, setValues: any) => {
    setValues({
      ...values,
      singleCopy: e.target.name === 'singleCopy' ? e.target.checked : values.singleCopy,
      edpCopyGroupName: '',
      count: 1,
      collectionId: undefined,
    });
  };

  const handleChangeVerificationType = async (e: any, setFieldValue: any) => {
    setFieldValue('copyAllDataset', false);
    if (e.target.value === VerificationType.EPDProcessCertification) {
      setFieldValue('verifierId', undefined, false);
    }
  };

  const handleClearFrom = (resetForm: any) => {
    confirmAlert({
      caption: 'Do you want to clear entered Source EPD data?',
      description: <div>All entered data will removed.</div>,
      acceptLabel: 'Clear data',
      rejectLabel: 'Cancel',
      onConfirm: () => {
        resetForm();
        setPcr(undefined);
      },
      onReject: () => {},
    });
  };

  const validationSchema = Yup.object().shape({
    edpCopyGroupName: Yup.string().when('singleCopy', {
      is: false,
      then: Yup.string().required(t('messages.required')),
    }),
    sourceEpdVersionId: Yup.string().required(t('messages.required')),
    collectionId: Yup.string().nullable(),
    practitionersIds: Yup.array<string>()
      .min(1, t('messages.required'))
      .test({
        name: 'not-same-verifier-company',
        message: t('epdWizard.wizardStep1.properties.epdDeveloper.messages.sameCompany'),
        test: function (value: any) {
          const selectedVerifierId = this.parent.verifierId;
          const epdVerifier = groupData?.verifiersSource?.find(
            (verifier: EPDVerifierModel) => verifier.contactUserId === selectedVerifierId
          );
          const selectedEpdDevelopers = groupData?.developersSource?.filter((developer: MembershipModel) =>
            value?.includes(developer.userId)
          );

          return (
            !selectedEpdDevelopers ||
            !epdVerifier ||
            selectedEpdDevelopers.length === 0 ||
            !selectedEpdDevelopers.find(
              (developer: MembershipModel) =>
                developer.companyId === epdVerifier.companyId &&
                developer.userId !== epdVerifier.contactUserId &&
                !epdVerifier.isExternalMembership
            )
          );
        },
      })
      .test({
        name: 'not-same-verifier-user',
        message: t('epdWizard.wizardStep1.properties.epdDeveloper.messages.sameUser'),
        test: function (value: any) {
          const selectedVerifierId = this.parent.verifierId;
          const epdVerifier = groupData?.verifiersSource?.find(
            (verifier: EPDVerifierModel) => verifier.contactUserId === selectedVerifierId
          );
          const selectedEpdDevelopers = groupData?.developersSource?.filter((developer: MembershipModel) =>
            value?.includes(developer.userId)
          );

          return (
            !selectedEpdDevelopers ||
            !epdVerifier ||
            selectedEpdDevelopers.length === 0 ||
            !selectedEpdDevelopers.find(
              (developer: EPDDeveloperModel) => developer.contactUserId === epdVerifier.contactUserId
            )
          );
        },
      }),
    verifierId: Yup.string()
      .nullable()
      .when('verificationType', {
        is: (verificationType) => verificationType !== VerificationType.EPDProcessCertification,
        then: Yup.string().required(t('messages.required')),
      })
      .test({
        name: 'not-same-developer-company',
        message: t('epdWizard.wizardStep1.properties.epdVerifier.messages.sameCompany'),
        test: function (value: any) {
          const selectedPractitionersIds = this.parent.practitionersIds as string[];
          const epdVerifier = groupData?.verifiersSource?.find(
            (verifier: EPDVerifierModel) => verifier.contactUserId === value
          );
          const selectedEpdDevelopers = groupData?.developersSource?.filter((developer: MembershipModel) =>
            selectedPractitionersIds?.includes(developer.userId)
          );

          return (
            !epdVerifier ||
            !selectedEpdDevelopers ||
            selectedEpdDevelopers.length === 0 ||
            !selectedEpdDevelopers.find(
              (developer: MembershipModel) =>
                developer.companyId === epdVerifier.companyId &&
                developer.userId !== epdVerifier.contactUserId &&
                !epdVerifier.isExternalMembership
            )
          );
        },
      })
      .test({
        name: 'not-same-developer',
        message: t('epdWizard.wizardStep1.properties.epdVerifier.messages.sameUser'),
        test: function (value: any) {
          const selectedPractitionersIds = this.parent.practitionersIds as string[];
          const epdVerifier = groupData?.verifiersSource?.find(
            (verifier: EPDVerifierModel) => verifier.contactUserId === value
          );
          const selectedEpdDevelopers = groupData?.developersSource?.filter((developer: MembershipModel) =>
            selectedPractitionersIds?.includes(developer.userId)
          );

          return (
            !epdVerifier ||
            !selectedEpdDevelopers ||
            selectedEpdDevelopers.length === 0 ||
            !selectedEpdDevelopers.find((developer: MembershipModel) => developer.userId === epdVerifier.contactUserId)
          );
        },
      }),
    pcr: Yup.object<PCRModel>().nullable(),
    count: Yup.number().required(t('messages.required')),
    verificationType: Yup.mixed<VerificationType>()
      .oneOf(Object.values(VerificationType))
      .nullable()
      .required(t('messages.required')),
    preverifiedToolId: Yup.string().nullable(),
    preverifiedToolVersionId: Yup.string().nullable(),
    keywordsList: Yup.array<string>(),
    productInformationSectionsList: Yup.array<EpdProductInformationEnum>(),
    machineReadableSectionsList: Yup.array<EpdMachineReadableEnum>(),
    isSectorEPD: Yup.boolean().nullable(),
  });

  const FormikPersist = ({ formikValues, setValues, setCopyGroupValues }: any) => {
    useEffect(() => {
      setCopyGroupValues(formikValues);
    }, [formikValues]);

    useEffect(() => {
      if (copyGroupValues && activeStep === 1 && previousStep !== activeStep) {
        setValues(copyGroupValues);
      }
    }, [activeStep]);

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleEPDCopyGroupCreate}
      enableReinitialize
    >
      {({ values, setValues, setFieldValue, resetForm, isSubmitting, dirty, submitCount, isValid }) => (
        <StyledForm>
          {groupData?.id === undefined && (
            <FormikPersist formikValues={values} setValues={setValues} setCopyGroupValues={setCopyGroupValues} />
          )}

          <Prompt when={dirty && submitCount === 0} message="Are you sure you want to leave? You have unsaved changes." />
          <FieldPanel>
            <FieldPanelHeader>EPD copy initiation</FieldPanelHeader>

            <FieldPanelInput>
              <InputFormField
                label={'Group copy name'}
                placeholder={values.singleCopy ? '' : 'Type group name'}
                name="edpCopyGroupName"
                maxLength={90}
                tooltip={
                  'All the copies you create will be placed in a group, you decide the name of the group in this field. This function is developed so that you can send all EPDs at the same time for verification, without having to click one by one. After publication, the group will cease and you will see all EPDs as single EPDs on your dashboard.'
                }
                tooltipPosition={'top | right'}
                required={!values.singleCopy}
                disabled={groupData?.id !== undefined || values.singleCopy}
              />
              {groupData?.id === undefined && (
                <AdditionalCheckBoxGroup>
                  <PrimeCheckbox
                    name="singleCopy"
                    label={'I need a single EPD copy without a group'}
                    handlePostChangeAction={(event: any) => {
                      handleChangeSingleCopy(event, values, setValues);
                    }}
                  />
                </AdditionalCheckBoxGroup>
              )}
            </FieldPanelInput>

            <SelectDataField
              label="Number of copies"
              tooltip="You can select up to 50 copies"
              tooltipPosition={'top | right'}
              name="count"
              options={Array.from({ length: 50 }, (_, i) => i + 1).map((i) => {
                return { value: i, label: i };
              })}
              required
              disabled={groupData?.id !== undefined || values.singleCopy === true}
            />

            <FieldPanelInput>
              <SelectDataField
                label="Source EPD"
                tooltip="Select source EPD to copy dataset for new EPD copies."
                tooltipPosition={'top | right'}
                name="sourceEpdVersionId"
                options={
                  groupData?.epdCopyGroupDataSources?.epdsToCopy.map((epds: DataSourceModel) => {
                    return {
                      value: epds.id,
                      label: epds.name,
                    } as Option;
                  }) ?? []
                }
                required
                isClearable
                disabled={groupData?.id !== undefined}
                handlePostChangeAction={(event: any) => {
                  handleChangeCopyAllDataSet(event, values, setValues);
                }}
              />
              {groupData?.id === undefined && (
                <AdditionalCheckBoxGroup>
                  <PrimeCheckbox
                    name="copyAllDataset"
                    label="Copy all information from this EPD"
                    handlePostChangeAction={(event: any) => {
                      handleChangeCopyAllDataSet(event, values, setValues);
                    }}
                  />
                </AdditionalCheckBoxGroup>
              )}
            </FieldPanelInput>
            <SelectDataField
              label="Add EPD copies to collection"
              tooltip={'You can add EPD copies to collection to keep the EPDs together after publication.'}
              tooltipPosition={'top | right'}
              name="collectionId"
              options={
                groupData?.epdCopyGroupDataSources?.collectionDataSource.map((collection: DataSourceModel) => {
                  return {
                    value: collection.id,
                    label: collection.name,
                  } as Option;
                }) ?? []
              }
              isClearable
              isDisabled={
                groupData?.id !== undefined ||
                groupData?.epdCopyGroupDataSources?.collectionDataSource?.length === 0 ||
                values.singleCopy
              }
            />
          </FieldPanel>
          <FieldPanel>
            <FieldPanelHeader>EPD verification</FieldPanelHeader>

            <SelectDataField
              label={t('epdWizard.wizardStep1.properties.verificationType.title')}
              name="verificationType"
              tooltip={'Select verification type'}
              tooltipPosition={'top | right'}
              options={
                groupData?.epdCopyGroupDataSources?.verificationTypeDataSource.map((value: VerificationType) => {
                  return {
                    value: value,
                    label: t(`verificationTypeEnum.${value}`),
                  } as Option;
                }) ?? []
              }
              isClearable
              required
              disabled={groupData?.id !== undefined}
              handlePostChangeAction={(event: any) => {
                handleChangeVerificationType(event, setFieldValue);
              }}
            />
            <SelectDataField
              label="EPD verifier"
              tooltip="If you don't find the right verifier in the list, invite EPD verifiers in your organization's settings."
              tooltipPosition={'top | right'}
              name="verifierId"
              options={
                groupData?.verifiersSource?.map((user) => {
                  return {
                    value: user.contactUserId,
                    label: user.displayName,
                  } as Option;
                }) ?? []
              }
              isClearable
              required={values.verificationType !== VerificationType.EPDProcessCertification}
              disabled={groupData?.id !== undefined || values.verificationType === VerificationType.EPDProcessCertification}
              handlePostChangeAction={() => {
                setFieldValue('copyAllDataset', false);
              }}
            />

            <SelectDataField
              label={t('epdWizard.wizardStep1.properties.preVerifiedTool.title')}
              name="preverifiedToolId"
              tooltip={
                'A pre-verified EPD tool contains data and calculation models to simplify the LCA calculation procedure based on a reference PCR. It is pre-verified to ensure that it produces correct data, given the correct input.'
              }
              tooltipPosition={'top | right'}
              options={
                groupData?.epdCopyGroupDataSources?.preverifiedToolDataSource.map((value: PreverifiedToolModel) => {
                  return {
                    value: value.id,
                    label: value.name,
                    isDisabled:
                      (value.name === 'EPD generator for Gunnar Prefab AB' && companyName !== 'Gunnar Prefab AB') ||
                      (value.id === 'ee378866-dc59-4c62-5008-08dac88134de' && companyName !== 'DOVISTA A/S'),
                  } as Option;
                }) ?? []
              }
              isClearable
              disabled={groupData?.id !== undefined}
              handlePostChangeAction={() => {
                setFieldValue('copyAllDataset', false);
              }}
            />

            <SelectDataField
              label={t('epdWizard.wizardStep1.properties.preVerifiedToolVersion.title')}
              name="preverifiedToolVersionId"
              tooltip={'Select pre-verified EPD tool version'}
              tooltipPosition={'top | right'}
              options={
                groupData?.epdCopyGroupDataSources?.preverifiedToolVersionDataSource
                  ?.filter((value: PreverifiedToolVersionModel) => value.preverifiedToolId === values.preverifiedToolId)
                  ?.map((value: PreverifiedToolVersionModel) => {
                    return {
                      value: value.id,
                      label: value.name,
                    } as Option;
                  }) ?? []
              }
              isClearable
              disabled={groupData?.id !== undefined || !values.preverifiedToolId}
              handlePostChangeAction={() => {
                setFieldValue('copyAllDataset', false);
              }}
            />
          </FieldPanel>

          <FieldPanel>
            <FieldPanelHeader>EPD dataset</FieldPanelHeader>

            <SelectDataField
              label="LCA practitioner"
              tooltip={
                "If you don't find the right verifier in the list, invite LCA practitioners in your organization's settings."
              }
              tooltipPosition={'top | right'}
              name="practitionersIds"
              options={developersDataSource}
              isClearable
              required
              isMulti
              disabled={groupData?.id !== undefined}
              handlePostChangeAction={() => {
                setFieldValue('copyAllDataset', false);
              }}
            />

            <SelectDataField
              label="Product information"
              name="productInformationSectionsList"
              tooltip={
                'In this drop-down menu, you can select one or more product information data, which is then copied from the source EPD.'
              }
              tooltipPosition={'top | right'}
              options={productInformationSectionDataSource}
              isMulti
              isClearable
              disabled={groupData?.id !== undefined}
              handlePostChangeAction={() => {
                setFieldValue('copyAllDataset', false);
              }}
            />

            <SelectDataField
              label="Information for machine-readable EPDs"
              name="machineReadableSectionsList"
              tooltip={
                'In this drop-down menu, you can select one or more product information data, which is then copied from the source EPD.'
              }
              tooltipPosition={'top | right'}
              options={machineReadableSectionDataSource}
              isMulti
              isClearable
              disabled={groupData?.id !== undefined}
              handlePostChangeAction={() => {
                setFieldValue('copyAllDataset', false);
              }}
            />

            {/* <FieldPanelInput>
              <InputFormField
                label={'Keywords'}
                name="keywordsList"
                tooltip={
                  'You can add keywords which can help to filter search results for a specified products included in the EPD in EPD library on environdec.com.'
                }
                tooltipPosition={'top | right'}
                maxLength={90}
                disabled
              />
            </FieldPanelInput> */}

            <FieldPanelInput>
              <PcrSelector
                label={'PCR'}
                tooltip={'Product category rules.'}
                tooltipPosition={'top | right'}
                pcr={pcr}
                onChangePcr={(pcr: PCRModel | undefined) => {
                  if (!pcr) {
                    setFieldValue('copyAllDataset', false);
                  }
                  setPcr(pcr);
                }}
                required
                disabled={groupData?.id !== undefined}
              />
            </FieldPanelInput>

            <SectorEPDCheckBoxPanel disabled={groupData?.id !== undefined}>
              <PrimeCheckbox
                name="isSectorEPD"
                label={t('epdWizard.wizardStep1.properties.sectorEpd')}
                tooltip={t('epdWizard.wizardStep1.tooltips.sectorEpd')}
                tooltipPosition={'top | right'}
                disabled={groupData?.id !== undefined}
                handlePostChangeAction={() => {
                  setFieldValue('copyAllDataset', false);
                }}
              />
            </SectorEPDCheckBoxPanel>
          </FieldPanel>
          <ButtonPanel visible={groupData?.id === undefined}>
            <SecondaryButton
              label="Clear data"
              disabled={groupData?.id !== undefined || (!dirty && !pcr)}
              onClick={() => {
                handleClearFrom(resetForm);
              }}
              outlined
              type="button"
            />
            <PrimaryButton
              style={{ marginLeft: '16px' }}
              label="Submit data"
              disabled={isSubmitting || !dirty || !isValid || !pcr || groupData?.id !== undefined}
              type="submit"
            />
          </ButtonPanel>
        </StyledForm>
      )}
    </Formik>
  );
};

export const FixedRow = styled.div`
  grid-column-gap: 10px;
  display: grid;
  grid-template-columns: 100%;
`;

const FormPanel = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 16px;
  padding: 30px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.primaryWhite};

  /* Shadow/Block */
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
`;

const ButtonPanel = styled(FormPanel)<{ visible?: string | boolean }>`
  justify-content: flex-end;
  padding: 16px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
`;

const FieldPanel = styled(FormPanel)`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 22px;
  justify-content: start;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

const FieldPanelHeader = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  ${(props) => props.theme.typography.headerCaptionSemiBold}
`;

const SectorEPDCheckBoxPanel = styled(CheckBoxPanel)<{ disabled?: string | boolean }>`
  margin-top: 0;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.elementsFilledInput : props.theme.colors.primaryOrange01};
  .p-checkbox,
  .p-checkbox .p-checkbox-box {
    width: 16px;
    height: 16px;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 10px;
    font-weight: 700;
  }
  .p-checkbox-box.p-highlight.p-disabled {
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }
`;

const AdditionalCheckBoxGroup = styled(CheckBoxGroup)`
  margin-top: 0.25rem;

  .p-checkbox .p-checkbox-box {
    width: 14px;
    height: 14px;
    border: 1px solid ${(props) => props.theme.colors.elementsButtonDisabled};
    border-radius: 2px;
  }
`;

export default WizardStep1;
