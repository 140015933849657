import 'primeflex/primeflex.css';

import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import { toaster } from 'components/v2/toast';
import { ProcessStatus } from 'constants/constants';
import { Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useRef, useState } from 'react';
import AuthService from 'services/AuthService';
import CollectionService from 'services/CollectionService';
import CollectionServiceV2 from 'services/CollectionServiceV2';
import { CollectionDashboardModel, EPDCollectionStatus } from 'services/EpdClient';
import MembershipService from 'services/MembershipService';

type TProps = {
  collection: CollectionDashboardModel;
  fetchCollections: () => {};
};

const CollectionTitleRow = ({ collection, fetchCollections }: TProps) => {
  const menu = useRef<Menu>(null);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const { companyId } = React.useContext(CompanyContext);
  const acceptCollectionDelete = async () => {
    try {
      await CollectionService.deleteCollection(collection.id!);
      toaster({
        severity: 'info',
        summary: 'Confirmed',
        details: 'Collection has been deleted',
      });
      fetchCollections();
    } catch (error) {
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Collection deletion error',
      });
      console.error(error);
    }
  };

  const handlePublish = async (id: string) => {
    try {
      const isOwner =
        (
          await MembershipService.getMembershipsByUserAndCompanyAndRole(
            companyId || '',
            Role.EPDOwner,
            AuthService.getUser()?.id!
          )
        ).length > 0;

      if (isOwner || AuthService.isAdmin()) {
        setStatus(ProcessStatus.Fetching);
        await CollectionServiceV2.publishCollection(id);
        toaster({
          severity: 'info',
          summary: 'The collection has been published!',
          details: 'Now it is available on EPD website in EPD library.',
        });
        fetchCollections();
        setStatus(ProcessStatus.Success);
      } else {
        toaster({
          severity: 'error',
          summary: 'Collection has not been published!',
          details:
            'Unfortunately, you are not authorized to publish this collection. Please contact the EPD owner who is authorized to publish this.',
        });
      }
    } catch (error) {
      toaster({
        severity: 'error',
        summary: 'Error',
        details: JSON.stringify(error),
      });
      setStatus(ProcessStatus.Error);
    }
  };

  const handleDepublish = async (id: string) => {
    try {
      const isOwner =
        (
          await MembershipService.getMembershipsByUserAndCompanyAndRole(
            companyId || '',
            Role.EPDOwner,
            AuthService.getUser()?.id!
          )
        ).length > 0;
      if (isOwner || AuthService.isAdmin()) {
        setStatus(ProcessStatus.Fetching);
        await CollectionServiceV2.depublishCollection(id);
        toaster({
          severity: 'info',
          summary: 'Success',
          details: 'Collection has been depublished',
        });
        fetchCollections();
        setStatus(ProcessStatus.Success);
      } else {
        toaster({
          severity: 'error',
          summary: 'Collection has not been depublished!',
          details:
            'Unfortunately, you are not authorized to depublish this collection. Please contact the EPD owner who is authorized to depublish this.',
        });
      }
    } catch (error) {
      toaster({
        severity: 'error',
        summary: 'Error',
        details: JSON.stringify(error),
      });

      setStatus(ProcessStatus.Error);
    }
  };

  const items: MenuItem[] = [
    {
      label: 'Publish collection',
      command: () => {
        handlePublish(collection.id!);
      },
      visible: collection.status !== EPDCollectionStatus.Published,
    },
    {
      label: 'Depublish collection',
      command: () => {
        handleDepublish(collection.id!);
      },
      visible: collection.status === EPDCollectionStatus.Published,
    },
    {
      label: 'Delete collection',
      command: () => {
        confirmDelete({
          caption: 'Are you sure you want to delete the collection?',
          description: 'The collection will be deleted and we will not be able to restore it.',
          onConfirm: () => {
            acceptCollectionDelete();
          },
        });
      },
    },
    {
      label: 'Environdec view',
      url: 'https://www.environdec.com/library/collection/' + collection.friendlyUrl,
      target: '_blank',
      visible: collection.status === EPDCollectionStatus.Published,
    },
  ];

  return (
    <div className="pl-4  pr-2 flex align-items-top justify-content-between gap-2">
      <div
        className="text-2xl font-bold flex-wrap"
        style={{ maxHeight: '4rem', textOverflow: 'ellipsis', overflow: 'hidden', wordBreak: 'break-all' }}
      >
        {collection.name}
      </div>
      <div>
        <Menu model={items} popup ref={menu} />
        <Button className="border-none surface-section text-gray-900" onClick={(e) => menu.current!.toggle(e)}>
          <i className="pi pi-ellipsis-v font-bold text-2xl" style={{ color: '#1E6052' }} />
        </Button>
      </div>
    </div>
  );
};

export default CollectionTitleRow;
