import { StatusAttentionIcon } from 'components/v2/icons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import styled from 'styled-components';

import EpdDetailsGridHeader from './EpdDetailsGridHeader';
import { isNullOrEmptyHtml } from 'pages/wizard/Validation';
import MachineReadableEPDKey from './MachineReadableEPDKey';

type TRow = {
  key: string;
  value: string | number | null | undefined;
  required?: boolean;
  isHtml?: boolean;
};

type TProps = {
  headerLabel: string;
  data: TRow[];
  onEdit?: any;
  isReadOnly?: boolean;
};

const EpdDetailsGrid = ({ headerLabel, onEdit, data, isReadOnly }: TProps) => {
  const keyBodyTemplate = (rowData: TRow) => {
    return (
      <div className="flex align-items-center" style={{ maxWidth: '18rem' }}>
        <span title={rowData.key} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {rowData.key}
        </span>
        {rowData.required &&
          (!rowData.value ||
            (rowData.isHtml && rowData.value.toString().trim() === '<p></p>') ||
            isNullOrEmptyHtml(rowData.value)) && <StatusAttentionIcon />}
      </div>
    );
  };

  const valueBodyTemplate = (rowData: TRow) => {
    return (
      <div className="flex">
        {rowData.isHtml ? (
          <ValueSpan dangerouslySetInnerHTML={{ __html: rowData.value?.toString() || '' }} />
        ) : (
          <span>{rowData.value}</span>
        )}
      </div>
    );
  };

  return (
    <>
      <StyledDataTable
        value={data}
        headerColumnGroup={<></>}
        showGridlines
        header={<EpdDetailsGridHeader headerLabel={headerLabel} onEdit={onEdit} isReadOnly={isReadOnly} />}
      >
        <Column field="key" body={keyBodyTemplate} style={{ width: '20rem' }} />
        <Column field="value" body={valueBodyTemplate} />
      </StyledDataTable>
    </>
  );
};

const StyledDataTable = styled(DataTable)`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
  .p-datatable-header {
    padding: 1rem 0 0.5rem 0;
    border-bottom: 3px solid ${(props) => props.theme.colors.elementsInputBorder};
    background-color: ${(props) => props.theme.colors.primaryWhite};
  }
  && .p-datatable-header {
    border-width: 0px 0px 1px 0px;
  }
  & .p-datatable-tbody > tr > td {
    padding: 10px 1rem;
  }
`;
const ValueSpan = styled.span`
  margin: 0;
  padding: 0;
  && div > span,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  && > P {
    margin: 0;
    padding: 0;
  }
`;

export default EpdDetailsGrid;
