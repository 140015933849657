import React, { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTableProps, DataTableValue, DataTableValueArray } from 'primereact/datatable';

interface UseGridRowSelectionViaCheckboxResult<T> {
  selectedRows: T[];
  setSelectedRows: React.Dispatch<React.SetStateAction<T[]>>;
  dataTableProps: DataTableProps<DataTableValueArray> | undefined;
  checkboxColumnNode: React.ReactNode;
}

/**
 * Use this hook to easily disable rows selection in a data table.
 * This reduces code duplication and you don't need to fight Typescript, if working with read-only tables.
 *
 * @param isReadOnly Whether selection is disabled
 * @returns
 */
export default function useGridRowSelectionViaCheckbox<T extends DataTableValue>(
  isReadOnly: boolean
): UseGridRowSelectionViaCheckboxResult<T> {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  return {
    selectedRows,
    setSelectedRows,
    dataTableProps: !isReadOnly
      ? {
          selectionMode: 'checkbox',
          selection: selectedRows,
          onSelectionChange: (e) => setSelectedRows(e.value as T[]),
        }
      : // When table is disabled, we need to unset all selection-related props,
        // otherwise you still can select rows using Tab key.
        undefined,
    checkboxColumnNode: <Column selectionMode={isReadOnly ? undefined : 'multiple'} headerStyle={{ width: '3rem' }} />,
  };
}
