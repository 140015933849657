import { TabPanel } from 'primereact/tabview';
import React from 'react';
import { EPDModel, FileLoadingModel } from 'services/EpdClient';
import { TabViewStyled } from 'styles/v2/Styles.styled';

import LcaResultsTab from './LcaResultsTab';
import ScenariosTab from './ScenariosTab';
import SpecificationTab from './SpecificationTab';
import SystemBoundaryTab from './SystemBoundaryTab';

const Step3: React.FunctionComponent<{
  epd?: EPDModel;
  validationState: any;
  onChangeEpd: any;
  fileLoading?: FileLoadingModel[];
}> = ({ epd, validationState, onChangeEpd, fileLoading }) => {
  return (
    <TabViewStyled>
      <TabPanel header="Specification">
        <SpecificationTab epdVersionId={epd?.versionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="System boundary">
        <SystemBoundaryTab
          epdId={epd?.id!}
          epdVersionId={epd?.versionId!}
          validationState={validationState}
          onChangeEpd={onChangeEpd}
          fileLoading={fileLoading}
          canUpload={true}
        />
      </TabPanel>
      <TabPanel header="Scenarios">
        <ScenariosTab epdVersionId={epd?.versionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Results">
        <LcaResultsTab epdId={epd?.id!} epdVersionId={epd?.versionId!} errors={validationState?.errors?.lcaResults} />
      </TabPanel>
    </TabViewStyled>
  );
};

export default Step3;
