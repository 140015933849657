import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import { PanelHeaderTemplateOptions } from 'primereact/panel';
import styled from 'styled-components';

import { PanelTitle } from '../Styles.styled';
import ToggleButton from './ToggleButton';

const PanelHeaderTemplate = (
  options: PanelHeaderTemplateOptions,
  title: string | undefined,
  tooltip?: string | undefined,
  tooltipPosition?: any | undefined,
  headerActionsNode?: React.ReactNode,
  error?: any
) => {
  return (
    <div
      className={`${options.className} justify-content-start w-full`}
      style={options.collapsed ? { borderRadius: '4px' } : { paddingTop: '16px', paddingBottom: '6px', minHeight: '30px' }}
    >
      <ElementsContainer>
        <PanelTitle>{title}</PanelTitle>
        <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'Center' }}>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {error && <TooltipErrorIcon content={error} containerStyle={{ position: 'relative' }} />}
          <ToggleButton options={options} />
        </div>
        {headerActionsNode && <div>{headerActionsNode}</div>}
      </ElementsContainer>
    </div>
  );
};

const ElementsContainer = styled.div`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;
`;

export default PanelHeaderTemplate;
