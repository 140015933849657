import heroImage from 'assets/images/hero-image.png';
import logo from 'assets/images/portal-logo.svg';
import PasswordInput from 'components/form/PasswordInput';
import TextInput from 'components/form/TextInput';
import { toaster } from 'components/v2/toast';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { LoginModel, UserModel } from 'services/EpdClient';
import styled from 'styled-components';
import { A, Button, H1, StyledForm, Text } from 'styles/Styles.styled';
import { EndColumn, HeaderLogo, LoginFlowA, StartColumn } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

interface Props {}

const Login: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { changeCompany } = useContext(CompanyContext);
  const history = useHistory();

  const initialValues: LoginModel = {
    userName: '',
    password: '',
  };

  const validationSchema = Yup.object({
    userName: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  const redirectToDashboard = (result: any) => {
    if (AuthService.isPcrUser()) {
      history.push(EpdRoutes.pcr.dashboard);
    } else if (result?.memberships && result?.memberships.length > 0) {
      changeCompany?.(result?.memberships[0].companyId);
      history.push(EpdLinks.dashboard());
    } else {
      history.push(EpdLinks.dashboard());
    }
  };

  const login = async (values: LoginModel, actions: any) => {
    let result: UserModel = {};
    try {
      result = await AuthService.login(values);
      localStorage.setItem('loggedInBefore', 'true');
    } catch (e) {
      const error = PoorMansError(e);
      toaster({ severity: 'error', summary: 'Error', details: error });
      return;
    }
    redirectToDashboard(result);
  };

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      redirectToDashboard(AuthService.getUser());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoginContainer>
      <StartColumn>
        <HeaderLogo src={logo} alt="EPD International" />
        <BodyContainer>
          <H1>Log in</H1>
          <Text style={{ marginTop: '10px', marginBottom: '60px' }}>
            New to the portal? <A href={'/register/user'}>Register</A>
          </Text>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => login(values, actions)}
          >
            {({ isSubmitting }) => (
              <StyledForm style={{ width: '100%' }}>
                <TextInput label="Email Address" name="userName" type="userName" placeholder="email@example.com" />
                <PasswordInput label="Password" name="password" placeholder="Type password here" />
                <LoginFlowA href={EpdRoutes.account.forgotPassword}>Forgot password?</LoginFlowA>
                <LoginButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Logging in...' : 'Login'}
                </LoginButton>
              </StyledForm>
            )}
          </Formik>
        </BodyContainer>
        <Footer>
          {t('footer.text')}
          <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>{'|'}</span>
          <A href={t('footer.link')}>{t('footer.linkText')}</A>
        </Footer>
      </StartColumn>
      <EndColumn>
        <img src={heroImage} alt="hero" style={{ maxHeight: '100vh', alignSelf: 'end' }} />
      </EndColumn>
    </LoginContainer>
  );
};

const LoginButton = styled(Button)`
  margin-top: 60px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  ${A} {
    color: ${(props) => props.theme.colors.primaryOrange};
  }
`;

const LoginContainer = styled.div`
  display: grid;
  width: 100%;
  ${BodyContainer} {
    justify-content: center;
  }
  ${A} {
    display: inline-block;
    text-align: right;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  margin-top: 2em;
`;

export default Login;
