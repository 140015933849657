import React, { ReactNode } from 'react';
import { EPDModel } from 'services/EpdClient';
import { QUERY_KEYS } from 'services/api/constants';
import { useUpdateContentDeclarationProduct, useUpdateEpdGeneralInformation } from 'services/api/mutations';
import { useEpdContentDeclarationProducts } from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanel,
  ExpandablePanelHeaderName,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
  PanelContainer,
} from 'styles/v2/Styles.styled';
import { EPDContentDeclarationProductType, EPDGeneralInformationModel, EPDProductType } from 'types/types';

import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import { ExpandableHelpBox } from '../help-boxes';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import { YES_NO, YesNoDataSource } from './constants';
import DangerMaterialsGrid from './grids/product-content/DangerMaterialsGrid';
import PackagingMaterialsGrid from './grids/product-content/PackagingMaterialsGrid';
import PanelContentGrid from './grids/product-content/PanelContentGrid';
import ProductComponentsGrid from './grids/product-content/ProductComponentsGrid';
import ToggleButton from './panels/toggleable/ToggleButton';

const ContentDeclarationTab: React.FunctionComponent<{
  epd?: EPDModel;
  epdVersionId: string;
  generalInformation: EPDGeneralInformationModel;
  validationState: any;
}> = ({ epd, epdVersionId, generalInformation, validationState }) => {
  const hasDangerMaterialsLookup = [
    { value: false, label: YES_NO.NO },
    { value: true, label: YES_NO.YES },
  ];

  const { errors, fieldsState } = validationState || {};
  const updateMutation = useUpdateEpdGeneralInformation(epdVersionId);
  const updateContentDeclarationProduct = useUpdateContentDeclarationProduct(epdVersionId);
  const contentDeclarationProducts = useEpdContentDeclarationProducts(epdVersionId).data;
  const averageProductContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.Average) ?? null;
  const bestProductContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.BestProduct) ?? null;
  const worstProductContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.WorstProduct) ?? null;
  const isPanelContentEnabled = epd?.cpcr?.registrationNumber?.toLocaleLowerCase()?.includes('c-pcr-014');

  const isBestCaseEnabled = !!contentDeclarationProducts?.find(
    (x) => x.type === EPDContentDeclarationProductType.BestProduct
  )?.productDescription;
  const isWorstCaseEnabled = !!contentDeclarationProducts?.find(
    (x) => x.type === EPDContentDeclarationProductType.WorstProduct
  )?.productDescription;

  const onChangeEpdGeneralInformation = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId: epd?.id, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const onChangeEpdContentDeclarationProduct = async (
    type: EPDContentDeclarationProductType,
    propertyName: string,
    val: any
  ) => {
    updateContentDeclarationProduct.mutate({
      type: type,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const headerTemplate = (options: any, header: ReactNode, tooltip?: string, tooltipPosition?: any | undefined) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          {header}
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
        </ExpandablePanelHeaderName>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  return (
    <>
      <FieldPanelFullWidth>
        {generalInformation?.productType === EPDProductType.Services && (
          <>
            <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'center' }}>
              <HeaderCaptionSemiBold className="white-space-nowrap">Declare content</HeaderCaptionSemiBold>
              {!generalInformation?.serviceIncludesProductInSystemBoundary && <TooltipErrorIcon content={'Required'} />}
            </div>
            <ExpandableHelpBox
              headerTemplate={(options) =>
                headerTemplate(options, <HelpBoldText>How to report “Declared content” values?</HelpBoldText>)
              }
              toggleable
            >
              <span>
                The gross weight of material in the content declaration shall cover 100% of one unit of product and its
                packaging. If there is more than 5% (post-consumer) recycled or biogenic content in the product, this shall
                be declared (if below 5%, this may be declared). If the share of biobased/recycled material is unknown, this
                part of the content declaration can be left out or be declared as 0% (a conservative estimate) or unknown.
              </span>
            </ExpandableHelpBox>
            <CompilerSelectControlledComponent
              label="Does the service include any physical products used within the defined system boundary?"
              tooltip="If the service involves a physical product that is used in the construction, maintenance, repair, replacement, refurbishment, or demolition of several construction works, the content of that product shall be declared in the content declaration"
              placeholder="Select"
              name="serviceIncludesProductInSystemBoundary"
              options={YesNoDataSource}
              value={YesNoDataSource.find(
                (item) => item.value === generalInformation?.serviceIncludesProductInSystemBoundary
              )}
              onChanged={onChangeEpdGeneralInformation}
              isClearable={false}
              error={errors?.serviceIncludesProductInSystemBoundary}
              required
            />
            {generalInformation?.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
              <WizardTextAreaComponent
                label="Explanation why content declaration is not relevant"
                placeholder="Type here"
                name="declarationIrrelevanceExplanation"
                value={generalInformation?.declarationIrrelevanceExplanation}
                onChanged={onChangeEpdGeneralInformation}
                error={errors?.declarationIrrelevanceExplanation}
                required
              />
            )}
          </>
        )}
        {generalInformation?.epdClassification && generalInformation?.epdClassification !== 1 && (
          <WizardTextAreaComponent
            label="Content declaration of multiple products"
            name="productDescription"
            value={averageProductContentDeclaration?.productDescription}
            onChanged={(name: any, value: any) => {
              onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.Average, name, value);
            }}
            placeholder="Type here"
            required={true}
            state={fieldsState}
            tooltip="In EPDs of multiple products or sector EPDs, it shall be described in the content declaration section what the content declaration represents."
          />
        )}
        <ProductComponentsGrid
          epdVersionId={epd?.versionId!}
          serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
          errors={errors?.averageProductComponents}
          queryKey={QUERY_KEYS.EPD_PRODUCT_COMPONENTS}
          contentDeclarationType={EPDContentDeclarationProductType.Average}
        />
        <PackagingMaterialsGrid
          epdVersionId={epd?.versionId!}
          serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
          errors={errors?.averagePackagingMaterials}
          queryKey={QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS}
          contentDeclarationType={EPDContentDeclarationProductType.Average}
        />
        {isPanelContentEnabled && (
          <PanelContentGrid
            epdVersionId={epd?.versionId!}
            queryKey={QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT}
            errors={errors?.averagePanelContents}
            contentDeclarationType={EPDContentDeclarationProductType.Average}
            serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
          />
        )}
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <CompilerSelectControlledComponent
          label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
          tooltip="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
          name="hasDangerMaterials"
          options={hasDangerMaterialsLookup}
          value={hasDangerMaterialsLookup.find((x) => x.value == averageProductContentDeclaration?.hasDangerMaterials)}
          onChanged={(name: any, value: any) => {
            onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.Average, name, value);
          }}
          placeholder="Select..."
          required={true}
          error={errors?.generalInformation?.hasDangerMaterials}
        />
        {averageProductContentDeclaration?.hasDangerMaterials && (
          <DangerMaterialsGrid
            epdVersionId={epd?.versionId!}
            errors={errors?.averageDangerMaterials}
            queryKey={QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS}
            contentDeclarationType={EPDContentDeclarationProductType.Average}
          />
        )}
      </FieldPanelFullWidth>
      {generalInformation?.epdClassification && generalInformation?.epdClassification !== 1 && (
        <ExpandablePanelMarginContainer>
          <ExpandablePanelMultiContainer
            headerTemplate={(options) =>
              headerTemplate(
                options,
                <HeaderCaptionSemiBold>Content declaration of scenario “best-case product”</HeaderCaptionSemiBold>
              )
            }
            toggleable
            collapsed
          >
            <FieldPanelFullWidth>
              <WizardTextInputComponent
                label="Best-case product"
                tooltip="The “best-case product” is defined as the product with the lowest GWP-GHG results."
                onChanged={(name: any, value: any) => {
                  onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.BestProduct, name, value);
                }}
                placeholder="Type here"
                error={errors?.comparisonWorstAndBestCase}
                name="productDescription"
                value={
                  contentDeclarationProducts?.find((product) => product.type == EPDContentDeclarationProductType.BestProduct)
                    ?.productDescription
                }
              />
            </FieldPanelFullWidth>
            {isBestCaseEnabled && (
              <FieldPanelFullWidth>
                <ProductComponentsGrid
                  epdVersionId={epd?.versionId!}
                  serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
                  queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_COMPONENTS}
                  contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                />
                <PackagingMaterialsGrid
                  epdVersionId={epd?.versionId!}
                  serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
                  queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_PACKAGING_MATERIALS}
                  contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                />
                {isPanelContentEnabled && (
                  <PanelContentGrid
                    epdVersionId={epd?.versionId!}
                    queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_PANEL_CONTENT}
                    contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                    serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
                  />
                )}
                <CompilerSelectControlledComponent
                  label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  tooltip="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  name="hasDangerMaterials"
                  options={hasDangerMaterialsLookup}
                  value={hasDangerMaterialsLookup.find((x) => x.value == bestProductContentDeclaration?.hasDangerMaterials)}
                  onChanged={(name: any, value: any) => {
                    onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.BestProduct, name, value);
                  }}
                  placeholder="Select..."
                />
                {bestProductContentDeclaration?.hasDangerMaterials && (
                  <DangerMaterialsGrid
                    epdVersionId={epd?.versionId!}
                    queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_DANGER_MATERIALS}
                    contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                  />
                )}
              </FieldPanelFullWidth>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      )}
      {generalInformation?.epdClassification && generalInformation?.epdClassification !== 1 && (
        <ExpandablePanelMarginContainer>
          <ExpandablePanelMultiContainer
            headerTemplate={(options) =>
              headerTemplate(
                options,
                <HeaderCaptionSemiBold>Content declaration of scenario “worst-case product”</HeaderCaptionSemiBold>
              )
            }
            toggleable
            collapsed
          >
            <FieldPanelFullWidth>
              <WizardTextInputComponent
                label="Worst-case product"
                tooltip="The “worst-case product” is defined as the product with the highest GWP-GHG results."
                onChanged={(name: any, value: any) => {
                  onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.WorstProduct, name, value);
                }}
                placeholder="Type here"
                name="productDescription"
                error={errors?.comparisonWorstAndBestCase}
                value={
                  contentDeclarationProducts?.find(
                    (product) => product.type == EPDContentDeclarationProductType.WorstProduct
                  )?.productDescription
                }
              />
            </FieldPanelFullWidth>
            {isWorstCaseEnabled && (
              <FieldPanelFullWidth>
                <ProductComponentsGrid
                  epdVersionId={epd?.versionId!}
                  serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
                  queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_COMPONENTS}
                  contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                />
                <PackagingMaterialsGrid
                  epdVersionId={epd?.versionId!}
                  serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
                  queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_PACKAGING_MATERIALS}
                  contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                />
                {isPanelContentEnabled && (
                  <PanelContentGrid
                    epdVersionId={epd?.versionId!}
                    queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_PANEL_CONTENT}
                    contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                    serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
                  />
                )}
                <CompilerSelectControlledComponent
                  label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  tooltip="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  name="hasDangerMaterials"
                  options={hasDangerMaterialsLookup}
                  value={hasDangerMaterialsLookup.find((x) => x.value == worstProductContentDeclaration?.hasDangerMaterials)}
                  onChanged={(name: any, value: any) => {
                    onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.WorstProduct, name, value);
                  }}
                  placeholder="Select..."
                />
                {worstProductContentDeclaration?.hasDangerMaterials && (
                  <DangerMaterialsGrid
                    epdVersionId={epd?.versionId!}
                    queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_DANGER_MATERIALS}
                    contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                  />
                )}
              </FieldPanelFullWidth>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      )}
    </>
  );
};

const ExpandablePanelMarginContainer = styled(PanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
`;

const HelpBoldText = styled.span`
  font-weight: 600;
`;

export default ContentDeclarationTab;
