import { Option } from 'types/types';

declare global {
  interface Array<T> {
    labelByValue(this: Option[], value: string | undefined): string | undefined;
  }
}

Array.prototype.labelByValue = function (value: string | undefined): string | undefined {
  return this.find((x: Option) => x.value === value)?.label;
};

export {};
