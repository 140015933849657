import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import styled from 'styled-components';

import { TextButton } from '../../../buttons';
import { ExpandPanelIcon } from '../../../icons';
import { PanelTitle } from '../Styles.styled';
import ToggleButton from '../toggleable/ToggleButton';

const GridPanelHeaderTemplate = (
  options: any,
  title: string | undefined,
  onMaximize: () => void,
  tooltip?: string | undefined,
  tooltipPosition?: any | undefined,
  error?: any
) => {
  const className = `${options.className} justify-content-start w-full`;

  return (
    <div
      className={className}
      style={options.collapsed ? { borderRadius: '4px' } : { paddingTop: '16px', paddingBottom: '6px', minHeight: '30px' }}
    >
      <ElementsContainer>
        <PanelTitle>{title}</PanelTitle>
        <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'Center' }}>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {error && <TooltipErrorIcon content={error} containerStyle={{ position: 'relative' }} />}
          <ToggleButton options={options} />
        </div>
        <span>
          <TextButton
            className="white-space-nowrap"
            style={{ cursor: 'pointer' }}
            label="Expand table"
            icon={ExpandPanelIcon}
            text
            onClick={onMaximize}
          />
        </span>
      </ElementsContainer>
    </div>
  );
};

const ElementsContainer = styled.div`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;
`;

export default GridPanelHeaderTemplate;
