import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { useEpdScenarioModule, useEpdScenarioUnits } from 'services/api/queries';
import styled, { css } from 'styled-components';
import { ScenarioModuleItem, ScenarioModuleRequest } from 'types/types';

import { DataTableStyled, Placeholder } from '../StyledGrid';

type TProps = {
  epdVersionId: string;
  scenarioId: string;
  module: string;
  moduleName: string;
};

const ModuleGridPdf = ({ epdVersionId, scenarioId, module, moduleName }: TProps) => {
  const { t } = useTranslation();

  const scenarioModule = useEpdScenarioModule({ epdVersionId, scenarioId, module } as ScenarioModuleRequest).data;
  const units = useEpdScenarioUnits().data;

  const descriptionBodyTemplate = (rowData: ScenarioModuleItem) => {
    return rowData?.mandatory ? (
      <DescriptionCell
        readonly={true}
        dangerouslySetInnerHTML={{
          __html: t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.description.${rowData.placeholder}`),
        }}
      />
    ) : (
      <DescriptionCell readonly={false}>
        {rowData?.placeholder && !rowData?.description ? (
          <Placeholder>
            {t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.description.${rowData.placeholder}`)}
          </Placeholder>
        ) : (
          rowData?.description ?? <Placeholder>Type here</Placeholder>
        )}
      </DescriptionCell>
    );
  };

  const valueBodyTemplate = (rowData: ScenarioModuleItem) => {
    return rowData?.value ? (
      rowData.value
    ) : rowData.placeholder ? (
      <Placeholder>
        {t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.value.${rowData.placeholder}`)}
      </Placeholder>
    ) : (
      <Placeholder>Type value as appropriate</Placeholder>
    );
  };

  const unitBodyTemplate = (rowData: ScenarioModuleItem) => {
    const option = units?.find((x) => x.value === rowData.unitId);
    return option && option !== null && <div dangerouslySetInnerHTML={{ __html: option.label }} />;
  };

  return (
    <ModuleDataTableStyled value={scenarioModule?.scenarioModuleItems} dataKey="id" showGridlines>
      <Column field="description" header={moduleName} body={descriptionBodyTemplate} style={{ width: '40%' }} />
      <Column field="value" header="Value" body={valueBodyTemplate} className="padding" style={{ width: '40%' }} />
      <Column field="unitId" header="Unit" body={unitBodyTemplate} className="padding" style={{ width: '40%' }} />
    </ModuleDataTableStyled>
  );
};

const ModuleDataTableStyled = styled(DataTableStyled)`
  border-collapse: collapse;
  .p-datatable-table {
    height: 100%;
  }
  .p-datatable-tbody > tr > td {
    padding: 0;
  }
  .p-datatable-tbody > tr > td.padding,
  .p-datatable-tbody > tr.p-datatable-emptymessage > td {
    padding: 0.5rem 1rem;
  }
  && .p-inputtext.p-dropdown-label.p-placeholder {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  .p-checkbox.p-component.p-disabled > .p-checkbox-box.p-component {
    opacity: 1;
    border-color: ${(props) => props.theme.colors.elementsDropDown};
  }
`;

const CellContainer = styled.div<{ readonly: boolean }>`
  ${(props) =>
    props.readonly &&
    css`
      background-color: ${(props) => props.theme.colors.elementsFilledInput};
    `}
  padding: 0.5rem 1rem;
  height: 100%;
`;

const DescriptionCell = styled(CellContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default ModuleGridPdf;
