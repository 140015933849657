import React, { useState } from 'react';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { useUpsertInfrastructureAndCapitalGoods, useUpsertLCASpecification } from 'services/api/mutations';
import {
  useDictionaryValues,
  useGetInfrastructureAndCapitalGoods,
  useGetLCASpecification,
  useLCASoftwareOptions,
} from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanel,
  FieldPanelFullWidth,
  FieldPanelHeader,
  HeaderCaptionSemiBold,
} from 'styles/v2/Styles.styled';
import {
  EPDBasedOnUnitEnum,
  InfrastructureAndCapitalGoodsModel,
  LCASoftwareOptionModel,
  LCASpecificationModel,
  Option,
  ScrapInputsContributionLevelEnum,
} from 'types/types';
import { getOptionsFromDictionary } from 'util/utils';

import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardHTMLEditor from '../epd-wizard-inputs/WizardHTMLEditor';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import { ExpandableHelpBox } from '../help-boxes';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import { YES_NO, YesNoDataSource } from './constants';
import ContributionOfScrapInputsGrid from './grids/contribution-of-scrap-inputs/ContributionOfScrapInputsGrid';
import ElectricityGrid from './grids/electricity/ElectricityGrid';
import TransportationPerformanceGrid from './grids/specification/TransportationPerformanceGrid';
import DeclarationOfCO2Panel from './panels/declaration-of-co2/DeclarationOfCO2Panel';
import ToggleButton from './panels/toggleable/ToggleButton';
import ConversionFactorSection from './sections/specification/ConversionFactorSection';
import DataSourceSection from './sections/specification/DataSourceSection';
import ReferenceFlowSection from './sections/specification/ReferenceFlowSection';
import DataQualityAssessmentAndReferenceYearsPanel from './panels/data-quality-assessment-and-ref-years/DataQualityAssessmentAndReferenceYearsPanel';

const SpecificationTab: React.FunctionComponent<{
  epdVersionId: string;
  validationState: any;
}> = ({ epdVersionId, validationState }) => {
  const { errors } = validationState || {};
  const [isConversionFactorLock, setIsConversionFactorLock] = useState<boolean>(false);

  const dictionariesQuery = useDictionaryValues();
  const lcaSoftwareOptions = useLCASoftwareOptions().data;
  const lcaSpecification = useGetLCASpecification(epdVersionId!).data;
  const updateMutationLCASpecification = useUpsertLCASpecification(epdVersionId);
  const infrastructureAndCapitalGoods = useGetInfrastructureAndCapitalGoods(epdVersionId!).data;

  const updateInfrastructureAndCapitalGoods = useUpsertInfrastructureAndCapitalGoods(epdVersionId);

  const epdBasedOnUnitOptions = getOptionsFromDictionary(dictionariesQuery.data, EPD_DICTIONARY_CODES.EPD_BASED_ON_UNIT);

  const scrapInputsContributionLevelOptions = getOptionsFromDictionary(
    dictionariesQuery.data,
    EPD_DICTIONARY_CODES.SCRAP_INPUT_CONTRIBUTION_LEVEL
  );
  const referenceFlowUnits =
    dictionariesQuery.data?.filter((x) => x.dictionaryCode === EPD_DICTIONARY_CODES.REFERENCE_FLOW_UNIT) || [];

  const onChangeLCASpecification = async (propertyName: string, val: any) => {
    const newData = { ...lcaSpecification, [propertyName]: val } as LCASpecificationModel;

    if (propertyName === 'lcaSoftware') {
      newData.lcaSoftwareVersion = undefined;
    }

    updateMutationLCASpecification.mutate(newData);
  };

  const onChangeInfrastructureAndCapitalGoods = async (propertyName: string, val: any) => {
    const newData = { ...infrastructureAndCapitalGoods, [propertyName]: val } as InfrastructureAndCapitalGoodsModel;

    updateInfrastructureAndCapitalGoods.mutate(newData);
  };

  const selectedEPDBasedOnUnit = epdBasedOnUnitOptions.find(
    (x) =>
      lcaSpecification?.epdBasedOnUnit &&
      x.value === Object.keys(EPDBasedOnUnitEnum).indexOf(lcaSpecification?.epdBasedOnUnit).toString()
  );

  const selectedLCASoftwareOption = lcaSoftwareOptions?.find(
    (x) => lcaSpecification?.lcaSoftware && x.id.toString() === lcaSpecification?.lcaSoftware.toString()
  );

  const selectedScrapInputsContributionLevel = scrapInputsContributionLevelOptions.find(
    (x) =>
      lcaSpecification?.scrapInputsContributionLevel &&
      x.value ===
        Object.keys(ScrapInputsContributionLevelEnum).indexOf(lcaSpecification?.scrapInputsContributionLevel).toString()
  );

  const getLCASoftwareVersionDataSource = (lcaSoftware: LCASoftwareOptionModel | undefined) => {
    if (lcaSoftware?.versionRequired) {
      return lcaSoftware.lcaSoftwareVersions;
    }

    if (!lcaSoftware) {
      return [] as Option[];
    }

    return [{ value: '', label: 'N/A' } as Option] as Option[];
  };

  const getSelectedLCASoftwareVersionOption = (
    lcaSoftware: LCASoftwareOptionModel | undefined,
    lcaSoftwareVersionId: string | undefined
  ) => {
    if (lcaSoftware?.versionRequired) {
      return (
        lcaSoftware?.lcaSoftwareVersions?.find(
          (x: Option) => x.value.toLowerCase() === lcaSoftwareVersionId?.toLowerCase()
        ) ?? null
      );
    }

    if (!lcaSoftware) {
      return null;
    }

    return { value: '', label: 'N/A' } as Option;
  };

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    errors?: any
  ) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {errors?.some((error: any) => !!error) && <TooltipErrorIcon content={'Required'} />}
          <ToggleButton options={options} withMargins />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  const headerHelpBoxTemplate = (options: any, header: string, tooltip?: string, tooltipPosition?: any | undefined) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HelpBoldText>{header}</HelpBoldText>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
        </ExpandablePanelHeaderName>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  return (
    <>
      <ExpandablePanelModule
        headerTemplate={(options) =>
          headerModuleTemplate(options, 'Infrastructure and capital goods', undefined, undefined, [
            errors?.infrastructureAndCapitalGoods,
            errors?.infrastructureAndCapitalGoods?.includedInProcesses,
            errors?.infrastructureAndCapitalGoods?.contributionLevel,
            errors?.infrastructureAndCapitalGoods?.description,
          ])
        }
        toggleable
      >
        <div
          style={{
            marginBottom: infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES ? 30 : 0,
          }}
        >
          <CompilerSelectControlledComponent
            label="Are infrastructure or capital goods included in any upstream, core or downstream processes?"
            name="includedInProcesses"
            options={YesNoDataSource}
            value={YesNoDataSource.find((item) => item.value === infrastructureAndCapitalGoods?.includedInProcesses)}
            onChanged={onChangeInfrastructureAndCapitalGoods}
            required
            placeholder="Select"
            isClearable={false}
            error={errors?.infrastructureAndCapitalGoods?.includedInProcesses}
          />
        </div>
        {infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES && (
          <div
            style={{
              marginBottom: infrastructureAndCapitalGoods?.contributionLevel === YES_NO.YES ? 30 : 0,
            }}
          >
            <CompilerSelectControlledComponent
              label="Do infrastructure and capital goods contribute more than 10% to the cradle-to-gate results for all the environmental impact indicators declared in the EPD?"
              name="contributionLevel"
              options={YesNoDataSource}
              value={YesNoDataSource.find((item) => item.value === infrastructureAndCapitalGoods?.contributionLevel)}
              onChanged={onChangeInfrastructureAndCapitalGoods}
              required
              placeholder="Select"
              isClearable={false}
              error={errors?.infrastructureAndCapitalGoods?.contributionLevel}
            />
          </div>
        )}
        {infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES &&
          infrastructureAndCapitalGoods?.contributionLevel === YES_NO.YES && (
            <>
              <div style={{ marginBottom: 30 }}>
                <WizardHTMLEditor
                  name="description"
                  value={infrastructureAndCapitalGoods?.description}
                  label="Detailed description of infrastructure and capital goods"
                  tooltip="If infrastructure/capital goods are included within the system boundaries, this shall be described in the EPD, unless they contribute less than 10% to the cradle-to-gate results to all of the environmental impact indicators declared in the EPD."
                  placeholder="Type here"
                  onChanged={onChangeInfrastructureAndCapitalGoods}
                  stripPastedStyles={true}
                  required={true}
                  error={errors?.infrastructureAndCapitalGoods?.description}
                />
              </div>
              <ExpandableHelpBox
                headerTemplate={(options) =>
                  headerHelpBoxTemplate(options, 'How to describe “Infrastructure and capital goods”?')
                }
                className="w-full"
                toggleable
              >
                <span>
                  <HelpBoldText>The detailed description</HelpBoldText> shall include which life-cycle stages or processes
                  that infrastructure/capital goods are included for. Furthermore, the description should include the type of
                  infrastructure/capital goodsincluded (e.g., factory building, manufacturing machinery, transport vehicles,
                  transport infrastructure, energy infrastructure).
                  <br /> If infrastructure/capital goods are included in a generic LCI dataset used and contributes more than
                  5% to the cradle-to-gate results of any of the environmental impact indicators , the name of the dataset
                  (including the database it has been derived from) shall be declared if the full dataset (i.e., not just
                  the infrastructure/capital goods).
                </span>
              </ExpandableHelpBox>
            </>
          )}
      </ExpandablePanelModule>
      <ExpandablePanelModule
        headerTemplate={(options) =>
          headerModuleTemplate(
            options,
            'Data quality assessment and reference years',
            'Note that “reference year” is not necessarily the year of data collection, but may, for example, be the latest year for which the dataset provider deems the dataset to be fully representative',
            undefined,
            [errors?.dataQualityAssessment]
          )
        }
        toggleable
      >
        <DataQualityAssessmentAndReferenceYearsPanel epdVersionId={epdVersionId} errors={errors?.dataQualityAssessment} />
      </ExpandablePanelModule>
      <FieldPanelFullWidth style={{ gridRowGap: 0, gridColumnGap: 0 }}>
        <CompilerSelectControlledComponent
          label="EPD based on declared or functional unit"
          tooltip="The output that represents the product."
          name="epdBasedOnUnit"
          options={epdBasedOnUnitOptions}
          value={selectedEPDBasedOnUnit}
          onChanged={onChangeLCASpecification}
          required={true}
          error={errors?.lcaSpecification?.epdBasedOnUnit}
          placeholder="Select"
          isClearable={false}
          containerStyle={{ marginBottom: '1rem' }}
        />
        <ExpandableHelpBox
          headerTemplate={(options) =>
            headerHelpBoxTemplate(options, 'Explanation hint for EPD based on declared or functional unit.')
          }
          className="w-full"
          toggleable
        >
          <span>
            The value in the declared content tables in step 2 will be changed accordingly to the selected unit:
            <br />
            Option 1: if choosing declared unit - <HelpBoldText>Biogenic material1, kg C/declared unit</HelpBoldText>.
            <br />
            Option 2: if choosing functional unit - <HelpBoldText>Biogenic material1, kg C/product</HelpBoldText>.
          </span>
        </ExpandableHelpBox>
      </FieldPanelFullWidth>
      {lcaSpecification?.epdBasedOnUnit && lcaSpecification?.epdBasedOnUnit === EPDBasedOnUnitEnum.FunctionalUnit && (
        <FieldPanelFullWidth>
          <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>
            Functional unit
            <TooltipHelpIcon content="A functional unit is defined as a quantified performance of a product system." />
          </FieldPanelHeader>
          <WizardTextAreaComponent
            label="Functional unit description"
            placeholder="Type here"
            name="functionalUnitDescription"
            value={lcaSpecification?.functionalUnitDescription}
            error={errors?.lcaSpecification?.functionalUnitDescription}
            required
            onChanged={onChangeLCASpecification}
          />
        </FieldPanelFullWidth>
      )}
      <ReferenceFlowSection
        epdVersionId={epdVersionId}
        unitDataSource={referenceFlowUnits}
        epdBasedOnUnit={selectedEPDBasedOnUnit?.value}
        errors={validationState?.errors}
        setIsConversionFactorLock={setIsConversionFactorLock}
      />
      <ConversionFactorSection
        epdVersionId={epdVersionId}
        epdBasedOnUnit={lcaSpecification?.epdBasedOnUnit}
        isConversionFactorLock={isConversionFactorLock}
        errors={validationState?.errors}
      />
      <DataSourceSection epdVersionId={epdVersionId} errors={validationState?.errors} />
      <ExpandablePanelModule headerTemplate={(options) => headerModuleTemplate(options, 'LCA-based information')} toggleable>
        <ExpandablePanelContent>
          <CompilerSelectControlledComponent
            label="LCA software"
            name="lcaSoftware"
            tooltip="Software that was used for the LCA modeling. If the Software is not found, please contact the Secretariat."
            options={lcaSoftwareOptions?.map((item: any) => {
              return {
                value: item?.id,
                label: item?.name,
              } as Option;
            })}
            required
            value={
              selectedLCASoftwareOption
                ? ({ label: selectedLCASoftwareOption.name, value: selectedLCASoftwareOption.id } as Option)
                : null
            }
            onChanged={onChangeLCASpecification}
            isClearable={false}
            placeholder="Select"
            error={errors?.lcaSoftware}
          />
          <CompilerSelectControlledComponent
            label="LCA software version"
            name="lcaSoftwareVersion"
            options={getLCASoftwareVersionDataSource(selectedLCASoftwareOption)}
            required={!selectedLCASoftwareOption || selectedLCASoftwareOption.versionRequired}
            value={getSelectedLCASoftwareVersionOption(selectedLCASoftwareOption, lcaSpecification?.lcaSoftwareVersion)}
            onChanged={onChangeLCASpecification}
            placeholder="Select"
            disabled={selectedLCASoftwareOption?.name === 'Other LCA software'}
            error={errors?.lcaSoftwareVersion}
          />
          {selectedLCASoftwareOption?.name === 'Other LCA software' && (
            <>
              <WizardTextInputComponent
                label={'LCA software name'}
                name="softwareName"
                placeholder="Type here"
                value={lcaSpecification?.softwareName}
                onChanged={onChangeLCASpecification}
                error={errors?.softwareName}
                required
              />
              <WizardTextInputComponent
                label={'Software version'}
                name="softwareVersion"
                placeholder="Type here"
                value={lcaSpecification?.softwareVersion}
                onChanged={onChangeLCASpecification}
                error={errors?.softwareVersion}
                required
              />
            </>
          )}
          <WizardTextAreaComponent
            containerClassName="w-full"
            label="Additional information about the underlying LCA-based information"
            placeholder="Type here"
            tooltip="This may include information about assumptions, deviations, justification, cut-off rules, data quality, and allocation. This includes also additional information that PCR and c-PCR(s) may require in given circumstances."
            value={lcaSpecification?.lcaBasedDescription}
            onChanged={onChangeLCASpecification}
            name="lcaBasedDescription"
          />
          <WizardTextAreaComponent
            containerClassName="w-full"
            label="List of characterisation methods"
            required
            placeholder="Type here"
            tooltip="List of characterisation methods for all declared environmental performance indicators, with reference to the source(s). The list shall also include a description of the version number (e.g., EF 3.0 or EF 3.1) of the EN 15804 reference package used, if applicable."
            value={lcaSpecification?.characterisationMethods}
            onChanged={onChangeLCASpecification}
            name="characterisationMethods"
            error={errors?.characterisationMethods}
          />
          <WizardTextAreaComponent
            containerClassName="w-full"
            label="Technology description including background system"
            name="technologyDescription"
            required
            placeholder="Type here"
            tooltip="Description of the technological characteristics including operating conditions of the product system"
            value={lcaSpecification?.technologyDescription}
            onChanged={onChangeLCASpecification}
            error={errors?.technologyDescription}
            rows={6}
          />
          <ExpandableHelpBox
            headerTemplate={(options) =>
              headerHelpBoxTemplate(options, 'Explanation hint for technology description including background system')
            }
            className="w-full"
            toggleable
          >
            <span>
              Give concentrated information about main technological aspects, to make the user understand the background of
              the LCA information in the EPD. E.g.
              <br />
              <ul>
                <li>1-2 sentences to describe the product if reasonable;</li>
                <li>Declaration of the main product components and/or materials;</li>
                <li>
                  Short description of the manufacturing process with focus on product specific information which are
                  relevant to understand the data set rather than general literature on the product group;
                </li>
                <li>Information on pre-products or raw materials if reasonable;</li>
                <li>Description of the construction process stage, use stage and end-of life stage if reasonable.</li>
              </ul>
              <HelpBoldText>Example for technical purpose of product:</HelpBoldText>
              <br />
              The products considered are Portland slag cement according to DIN EN 197-1.
              <br />
              The product consists of Portland cement clinker and blast furnace slag as well as sulphate carriers.
              <br />
              The blast furnace content is between 21 and 35 M .-%.
            </span>
          </ExpandableHelpBox>
        </ExpandablePanelContent>
      </ExpandablePanelModule>
      {lcaSpecification?.shouldTransportationPerformanceBeFetched && (
        <FieldPanelFullWidth style={{ gridRowGap: 0 }}>
          <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>
            Transportation performance (TP){' '}
            <TooltipHelpIcon
              content={
                'The total amount of tkm fulfilled by the lift during its lifetime, known as transportation performance (TP), is calculated according to the PCR and ISO 25745-2. The term "transportation performance (TP)" used to indicate the total amount of tkm is identical both in meaning and in calculation approach to the term “total number of FU” used in EPDs based on PCR 2015:05.'
              }
            />
            {errors?.transportationPerformance && (
              <TooltipErrorIcon content={errors?.transportationPerformance?.general || 'Required or has errors'} />
            )}
          </FieldPanelHeader>
          <br />
          <div style={{ overflowX: 'hidden', marginBottom: '20px' }}>
            <TransportationPerformanceGrid epdVersionId={epdVersionId} />
          </div>
        </FieldPanelFullWidth>
      )}
      {lcaSpecification?.shouldDeclarationsBeFetched && (
        <DeclarationOfCO2Panel
          epdVersionId={epdVersionId}
          cO2CarbonationError={errors?.cO2Carbonation}
          declarationOfCO2AssumptionsError={errors?.declarationOfCO2Assumptions}
        />
      )}
      <FieldPanelFullWidth style={{ gridRowGap: 0 }}>
        <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>
          Electricity data
          <TooltipHelpIcon content="The energy source behind electricity used in the manufacturing process in A3 and its climate impact as kg CO2 eq./kWh (using the GWP-GHG indicator)" />
          {errors?.electricity && <TooltipErrorIcon content={errors?.electricity?.general || 'Required or has errors'} />}
        </FieldPanelHeader>
        <ElectricityGrid epdVersionId={epdVersionId} errors={errors?.electricity} />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <FieldPanelHeader style={{ display: 'flex', alignItems: 'center' }}>
          Contribution of scrap inputs to gwp-ghg in modules a1-a3{' '}
          <TooltipHelpIcon
            content={
              'If the scrap inputs contribute more than 10% to the GWP-GHG results of modules A1-A3: the climate impact (in kg CO2 eq./tonne using the GWP GHG indicator) of each input scrap and the percentage of scrap that was assumed to come with, and without, an environmental burden.'
            }
          />
        </FieldPanelHeader>
        <CompilerSelectControlledComponent
          label="Scrap inputs contribution level"
          name="scrapInputsContributionLevel"
          options={scrapInputsContributionLevelOptions}
          value={selectedScrapInputsContributionLevel}
          onChanged={onChangeLCASpecification}
          required
          error={errors?.lcaSpecification?.scrapInputsContributionLevel}
          placeholder="Select"
          isClearable={false}
        />
        {lcaSpecification?.scrapInputsContributionLevel &&
          lcaSpecification?.scrapInputsContributionLevel === ScrapInputsContributionLevelEnum.MorePermissiblePercent && (
            <ContributionOfScrapInputsGrid epdVersionId={epdVersionId} errors={errors?.scrapInputs} />
          )}
      </FieldPanelFullWidth>
    </>
  );
};

const HelpBoldText = styled.span`
  font-weight: 600;
`;

const ExpandablePanelContent = styled(FieldPanel)`
  padding: 0;
  margin-top: 0;
`;

export default SpecificationTab;
