import React from 'react';
import { ExpandablePanel, PanelContainer } from 'styles/v2/Styles.styled';

import PanelHeaderTemplate from './PanelHeaderTemplate';

type TProps = React.PropsWithChildren<{
  title: string;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  error?: any;
  style?: any;
  headerActionsNode?: React.ReactNode;
}>;
const ToggleablePanel = ({
  title,
  headerActionsNode,
  children,
  tooltip,
  tooltipPosition,
  error,
  style,
}: TProps) => {
  return (
    <PanelContainer>
      <ExpandablePanel
        headerTemplate={(options) => PanelHeaderTemplate(options, title, tooltip, tooltipPosition, headerActionsNode, error)}
        toggleable
        data-pr-tooltip=""
        collapsed
      >
        <div style={style}>{children}</div>
      </ExpandablePanel>
    </PanelContainer>
  );
};

export default ToggleablePanel;
