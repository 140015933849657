import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import calendarIcon from 'images/calendar-6.svg';
import { FixedLabel } from 'pages/wizard/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  AdminBox,
  AdminSearchGridForm,
  ButtonSmall,
  Container,
  EditFull,
  ErrorMessage,
  H2,
  LabelBox,
} from 'styles/Styles.styled';

import * as Yup from 'yup';

const AdminVerifiers: React.FunctionComponent = () => {
  const [isSmaller, setIsSmaller] = useState(false);

  const initialValues = {
    fromDate: '',
    toDate: '',
  };
  const validationSchema = Yup.object({
    fromDate: Yup.date().required('From date is required'),
    toDate: Yup.date().required('To date is required'),
  });
  const baseUrl =
    (process.env.REACT_APP_API_URL || 'https://localhost:44380') + '/api/admin/v1/AdminVerifiers/DownloadReviewOfVerifiers?';

  const onSearch = async (values: any) => {
    const downloadVerifiers = async () => {
      const date1 = new Date(values?.fromDate);
      const date2 = new Date(values?.toDate);
      if (date2 >= date1) {
        const params = new URLSearchParams({
          FromDate: date1.toISOString().split('T')[0],
          ToDate: date2.toISOString().split('T')[0],
        });
        const newUrl = `${baseUrl}${params.toString()}`;
        window.open(newUrl);
      } else {
        setIsSmaller(true);
      }
    };
    downloadVerifiers();
  };

  return (
    <Container>
      <EditFull>
        <H2>Review of verifiers</H2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => onSearch(values)}>
          <AdminBox>
            <CustomSearch>
              <LabelBox>
                <FixedLabel>Period</FixedLabel>
              </LabelBox>
              <FromDateContainer>
                <StyledTextInput type="date" placeholder="yyyy-mm-dd" name="fromDate" />
              </FromDateContainer>
              <Partition>
                <span>-</span>
              </Partition>
              <ToDateContainer>
                <StyledTextInput type="date" placeholder="yyyy-mm-dd" name="toDate" />
              </ToDateContainer>
              <VerifiersSearchBox>
                <ButtonSmall style={{ padding: '0.5rem 1rem', borderRadius: '3px' }} type="submit">
                  Download
                </ButtonSmall>
              </VerifiersSearchBox>
            </CustomSearch>
          </AdminBox>
        </Formik>
        {isSmaller && (
          <ErrorMessage style={{ marginTop: '1rem', color: 'red' }}>
            From date should be equal or smaller than the End date.
          </ErrorMessage>
        )}
      </EditFull>
    </Container>
  );
};

const CustomSearch = styled(AdminSearchGridForm)`
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
  max-width: 100%;
  display: grid;
  grid-template-rows: 3;
  justify-content: start;
  align-items: center;
  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 15% 10% 10% 10% 2% 35%;
  }
  ${(props) => props.theme.media.desktop} {
    max-width: 60%;
    grid-template-columns: 25% 10% 10% 10% 2% 35%;
  }
`;

const StyledTextInput = styled(TextInput)`
  box-sizing: border-box;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 27px 27px;
  background-position: right 35px center;
  background-image: url(${calendarIcon});
  min-width: 70%;

  ${(props) => props.theme.media.desktop} {
    min-width: 100%;
  }
`;

const FromDateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: start;
  grid-column-start: 1;
  grid-column-end: -1;
  ${(props) => props.theme.media.tablet} {
    grid-column-start: 2;
    grid-column-end: 5;
  }
`;

const Partition = styled(FromDateContainer)`
grid-column-start: 1;
    grid-column-end: -1;
  padding-top: 1.5rem;  
  span {
    display: none;
  }
  ${(props) => props.theme.media.tablet} {
    grid-column-start: 5;
    grid-column-end: 6;
    span {
      display: inline-block;
    }
`;

const ToDateContainer = styled(FromDateContainer)`
  display: flex;
  flex-wrap: wrap;
  align-self: start;
  grid-column-start: 1;
  grid-column-end: -1;
  ${(props) => props.theme.media.tablet} {
    grid-column-start: 6;
    grid-column-end: 7;
  }
`;

const VerifiersSearchBox = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  align-self: start;
  ${(props) => props.theme.media.tablet} {
    grid-column-start: 2;
    grid-column-end: -1;
  }
`;

export default AdminVerifiers;
