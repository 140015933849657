import React from 'react';
import { PDF_H1, PDF_H2, PDF_P, PDF_PAGE, PDF_Table } from './Styles.styled';
import ProductComponentsGridPdf from 'components/v2/compiler/grids/product-content/ProductComponentsGridPdf';
import PackagingMaterialsGridPdf from 'components/v2/compiler/grids/product-content/PackagingMaterialsGridPdf';
import DangerMaterialsGridPdf from 'components/v2/compiler/grids/product-content/DangerMaterialsGridPdf';
import { EPDContentDeclarationProductType, EPDPDFModel } from 'types/types';
import PanelContentGridPdf from 'components/v2/compiler/grids/product-content/PanelContentGridPdf';
import { ISO_21930_ID, YES_NO } from 'components/v2/compiler/constants';
import { useEPDDefinitionInformation } from 'services/api/queries';
import PackagingMaterialsISO21930GridPdf from 'components/v2/compiler/grids/product-content/PackagingMaterialsISO21930GridPdf';
import PanelContentISO21930GridPdf from 'components/v2/compiler/grids/product-content/PanelContentISO21930GridPdf';
import ProductComponentsISO21930GridPdf from 'components/v2/compiler/grids/product-content/ProductComponentsISO21930GridPdf';
import { QUERY_KEYS } from 'services/api/constants';

const ContentDeclarationPage: React.FunctionComponent<{
  e: EPDPDFModel;
}> = ({ e }) => {
  const isPanelContentEnabled = e?.pcrInformation?.cpcr?.name?.includes('c-PCR-014');
  const epdDefinitionInformation = useEPDDefinitionInformation(e?.generalInformation?.epdVersionId).data;
  const selectedStandards = epdDefinitionInformation?.declaredStandards?.split(',');
  const isISO21930StandardSelected = selectedStandards?.some((x) => x === ISO_21930_ID);
  const isBestCaseEnabled = !!e?.contentDeclarationProducts?.find(
    (x) => x.type === EPDContentDeclarationProductType.BestProduct
  )?.productDescription;
  const isWorstCaseEnabled = !!e?.contentDeclarationProducts?.find(
    (x) => x.type === EPDContentDeclarationProductType.WorstProduct
  )?.productDescription;
  const averageProductContentDeclaration =
    e?.contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.Average) ?? null;
  const bestProductContentDeclaration =
    e?.contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.BestProduct) ?? null;
  const worstProdutContentDeclaration =
    e?.contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.WorstProduct) ?? null;

  return (
    <>
      <PDF_PAGE>
        <PDF_H1>Content declaration</PDF_H1>
        <br />
        {e.generalInformation.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
          <>
            <PDF_H2>Explanation why content declaration is not relevant</PDF_H2>
            <PDF_P>{e.generalInformation.declarationIrrelevanceExplanation}</PDF_P>
          </>
        )}

        {isISO21930StandardSelected ? (
          <ProductComponentsISO21930GridPdf
            epdVersionId={e.generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
          />
        ) : (
          <ProductComponentsGridPdf
            epdVersionId={e.generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
            queryKey={QUERY_KEYS.EPD_PRODUCT_COMPONENTS}
            contentDeclarationType={EPDContentDeclarationProductType.Average}
          />
        )}

        {isPanelContentEnabled && (
          <>
            <br />
            <br />
            {isISO21930StandardSelected ? (
              <PanelContentISO21930GridPdf epdVersionId={e.generalInformation.epdVersionId} />
            ) : (
              <PanelContentGridPdf
                epdVersionId={e.generalInformation.epdVersionId}
                queryKey={QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT}
                contentDeclarationType={EPDContentDeclarationProductType.Average}
              />
            )}
          </>
        )}
        <br />
        <br />
        {isISO21930StandardSelected ? (
          <PackagingMaterialsISO21930GridPdf
            epdVersionId={e.generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
          />
        ) : (
          <PackagingMaterialsGridPdf
            epdVersionId={e.generalInformation.epdVersionId}
            serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
            queryKey={QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS}
            contentDeclarationType={EPDContentDeclarationProductType.Average}
          />
        )}
        {averageProductContentDeclaration?.hasDangerMaterials && (
          <>
            <br />
            <br />
            <DangerMaterialsGridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              contentDeclarationType={EPDContentDeclarationProductType.Average}
              queryKey={QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS}
            />
          </>
        )}
      </PDF_PAGE>

      {isBestCaseEnabled && (
        <PDF_PAGE>
          <PDF_H1>Best-case product</PDF_H1>
          <PDF_Table>
            <thead>
              <tr>
                <th style={{ width: '30%' }}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product name</td>
                <td>{bestProductContentDeclaration?.productDescription}</td>
              </tr>
            </tbody>
          </PDF_Table>
          <br />
          {e.generalInformation.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
            <>
              <PDF_H2>Explanation why content declaration is not relevant</PDF_H2>
              <PDF_P>{e.generalInformation.declarationIrrelevanceExplanation}</PDF_P>
            </>
          )}
          {isISO21930StandardSelected ? (
            <ProductComponentsISO21930GridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
            />
          ) : (
            <ProductComponentsGridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
              queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_COMPONENTS}
              contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
            />
          )}
          {isPanelContentEnabled && (
            <>
              <br />
              <br />
              {isISO21930StandardSelected ? (
                <PanelContentISO21930GridPdf epdVersionId={e.generalInformation.epdVersionId} />
              ) : (
                <PanelContentGridPdf
                  epdVersionId={e.generalInformation.epdVersionId}
                  queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_PANEL_CONTENT}
                  contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                />
              )}
            </>
          )}
          <br />
          <br />
          {isISO21930StandardSelected ? (
            <PackagingMaterialsISO21930GridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
            />
          ) : (
            <PackagingMaterialsGridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
              queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_PACKAGING_MATERIALS}
              contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
            />
          )}
          {bestProductContentDeclaration?.hasDangerMaterials && (
            <>
              <br />
              <br />
              <DangerMaterialsGridPdf
                epdVersionId={e.generalInformation.epdVersionId}
                contentDeclarationType={EPDContentDeclarationProductType.BestProduct}
                queryKey={QUERY_KEYS.EPD_BEST_PRODUCT_DANGER_MATERIALS}
              />
            </>
          )}
        </PDF_PAGE>
      )}

      {isWorstCaseEnabled && (
        <PDF_PAGE>
          <PDF_H1>Worst-case product</PDF_H1>
          <PDF_Table>
            <thead>
              <tr>
                <th style={{ width: '30%' }}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product name</td>
                <td>{worstProdutContentDeclaration?.productDescription}</td>
              </tr>
            </tbody>
          </PDF_Table>
          <br />
          {e.generalInformation.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
            <>
              <PDF_H2>Explanation why content declaration is not relevant</PDF_H2>
              <PDF_P>{e.generalInformation.declarationIrrelevanceExplanation}</PDF_P>
            </>
          )}
          {isISO21930StandardSelected ? (
            <ProductComponentsISO21930GridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
            />
          ) : (
            <ProductComponentsGridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
              queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_COMPONENTS}
              contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
            />
          )}
          {isPanelContentEnabled && (
            <>
              <br />
              <br />
              {isISO21930StandardSelected ? (
                <PanelContentISO21930GridPdf epdVersionId={e.generalInformation.epdVersionId} />
              ) : (
                <PanelContentGridPdf
                  epdVersionId={e.generalInformation.epdVersionId}
                  queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_PANEL_CONTENT}
                  contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                />
              )}
            </>
          )}
          <br />
          <br />
          {isISO21930StandardSelected ? (
            <PackagingMaterialsISO21930GridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
            />
          ) : (
            <PackagingMaterialsGridPdf
              epdVersionId={e.generalInformation.epdVersionId}
              serviceIncludesProductInSystemBoundary={e.generalInformation.serviceIncludesProductInSystemBoundary}
              queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_PACKAGING_MATERIALS}
              contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
            />
          )}
          {bestProductContentDeclaration?.hasDangerMaterials && (
            <>
              <br />
              <br />
              <DangerMaterialsGridPdf
                epdVersionId={e.generalInformation.epdVersionId}
                contentDeclarationType={EPDContentDeclarationProductType.WorstProduct}
                queryKey={QUERY_KEYS.EPD_WORST_PRODUCT_DANGER_MATERIALS}
              />
            </>
          )}
        </PDF_PAGE>
      )}
    </>
  );
};

export default ContentDeclarationPage;
