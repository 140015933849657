import React from 'react';
import { Column, ColumnEvent, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {
  useCreateEpdLcaResultAdditional,
  useDeleteEpdLcaResultAdditional,
  useUpdateEpdLcaResultAdditional,
} from 'services/api/mutationsLcaResults';
import { useEpdLcaResultsAdditional } from 'services/api/queries';
import { LcaResultModel } from 'types/types';

import { ColumnFooterInputText, DataTableStyled } from '../StyledGrid';
import DynamicGridHeader from '../DynamicGridHeader';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

type TProps = {
  epdVersionId: string;
  columns?: ColumnProps[] | undefined;
  notes?: { note: React.ReactNode | string; description: React.ReactNode | string }[] | undefined;
};
const LcaResultsGridExtended = ({ epdVersionId, columns, notes }: TProps) => {
  const isReadOnly = useIsReadOnlyMode();
  const { selectedRows, setSelectedRows, dataTableProps, checkboxColumnNode } =
    useGridRowSelectionViaCheckbox<LcaResultModel>(isReadOnly);

  const rows = useEpdLcaResultsAdditional(epdVersionId).data;
  const createMutation = useCreateEpdLcaResultAdditional();
  const updateMutation = useUpdateEpdLcaResultAdditional(epdVersionId);
  const deleteMutation = useDeleteEpdLcaResultAdditional();

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const handleAddRow = () => {
    createMutation.mutate({ epdVersionId });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows.length) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows([]);
  };

  const header = (
    <DynamicGridHeader
      caption="Indicators data"
      onAddRow={handleAddRow}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={!selectedRows || selectedRows.length === 0 || deleteMutation.isPending}
    />
  );

  const footerGroup = notes ? (
    <ColumnGroup>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column
            style={{ padding: '2px' }}
            footer={
              <ColumnFooterInputText
                type="text"
                defaultValue={x.description as string}
                onBlur={(e) => {
                  // TODO save the value
                }}
                disabled={isReadOnly}
              />
            }
            colSpan={columns?.length}
            className={isReadOnly ? 'p-row-note' : undefined}
          />
        </Row>
      ))}
    </ColumnGroup>
  ) : null;

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  return (
    <DataTableStyled
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      footerColumnGroup={footerGroup}
      header={header}
      editMode="cell"
      rowClassName={rowClassName}
      {...dataTableProps}
    >
      {checkboxColumnNode}

      {columns?.map((x) => (
        <Column
          key={x.field}
          {...x}
          onCellEditComplete={x.editor ? onCellEditComplete : undefined}
          {...(isReadOnly && { editor: undefined })}
        />
      ))}
    </DataTableStyled>
  );
};

export default LcaResultsGridExtended;
