import React, { useState } from 'react';
import { ExpandablePanel, MaximizedDialog, PanelContainer } from 'styles/v2/Styles.styled';

import GridPanelHeaderTemplate from './GridPanelHeaderTemplate';

type TProps = React.PropsWithChildren<{
  title: string;
  tooltip?: string | undefined;
  tooltipPosition?: any | undefined;
  error?: any;
}>;
const GridExpandablePanel = ({ title, children, tooltip, tooltipPosition, error }: TProps) => {
  const [visible, setVisible] = useState(false);
  const onMaximize = () => {
    setVisible(true);
  };

  return (
    <PanelContainer>
      {visible /* this is done intentionally despite dialog has its visibility - otherwise issue with page scrollbar or full screen size of the dialog*/ && (
        <MaximizedDialog
          header={title}
          visible={visible}
          maximizable={true}
          maximized={true}
          onHide={() => setVisible(false)}
        >
          {children}
        </MaximizedDialog>
      )}

      <ExpandablePanel
        headerTemplate={(options) => GridPanelHeaderTemplate(options, title, onMaximize, tooltip, tooltipPosition, error)}
        toggleable
        data-pr-tooltip="cscs"
        collapsed
      >
        <div style={{ overflowX: 'hidden' }}>{children}</div>
      </ExpandablePanel>
    </PanelContainer>
  );
};

export default GridExpandablePanel;
