import { useEffect, useState } from 'react';
import { DataTableValue, DataTableValueArray } from 'primereact/datatable';

import { LcaResultsGridColumnsPdf } from './LcaResultsGridColumns';
import LcaResultsGridPdf from './LcaResultsGridPdf';

type TProps = {
  rows: DataTableValueArray | undefined;
  isAdditionalDisclaimerVisible: boolean | undefined;
};

const LcaResultsGridAdditionalConstructionPdf = ({ rows, isAdditionalDisclaimerVisible }: TProps) => {
  const [formattedRows, setFormattedRows] = useState(rows);

  const updateFormattedRow = (
    row: DataTableValue,
    formattedEtpFwRow: DataTableValue,
    formattedHtpCRow: DataTableValue,
    formattedHtpNCRow: DataTableValue,
    formattedSqpRow: DataTableValue
  ) => {
    switch (row.indicatorName) {
      case 'ETP-fw':
        return formattedEtpFwRow;
      case 'HTP-c':
        return formattedHtpCRow;
      case 'HTP-nc':
        return formattedHtpNCRow;
      case 'SQP':
        return formattedSqpRow;
      default:
        return row;
    }
  };

  useEffect(() => {
    if (!!rows?.length) {
      let etpFwRow = rows.find((x) => x.indicatorName === 'ETP-fw');
      let htpCRow = rows.find((x) => x.indicatorName === 'HTP-c');
      let htpNCRow = rows.find((x) => x.indicatorName === 'HTP-nc');
      let sqpRow = rows.find((x) => x.indicatorName === 'SQP');

      if (!etpFwRow || !htpCRow || !htpNCRow || !sqpRow) return;

      const formattedEtpFwRow = {
        ...etpFwRow,
        indicatorName: isAdditionalDisclaimerVisible ? 'ETP-fw<sup>2</sup>, <sup>3</sup>' : 'ETP-fw<sup>2</sup>',
      };

      const formattedHtpCRow = {
        ...htpCRow,
        indicatorName: isAdditionalDisclaimerVisible ? 'HTP-c<sup>2</sup>, <sup>3</sup>' : 'HTP-c<sup>2</sup>',
      };

      const formattedHtpNCRow = {
        ...htpNCRow,
        indicatorName: isAdditionalDisclaimerVisible ? 'HTP-nc<sup>2</sup>, <sup>3</sup>' : 'HTP-nc<sup>2</sup>',
      };

      const formattedSqpRow = {
        ...sqpRow,
        indicatorName: isAdditionalDisclaimerVisible ? 'SQP<sup>2</sup>, <sup>3</sup>' : 'SQP<sup>2</sup>',
      };

      setFormattedRows((prev) =>
        rows.map(
          (row) =>
            updateFormattedRow(
              row,
              formattedEtpFwRow,
              formattedHtpCRow,
              formattedHtpNCRow,
              formattedSqpRow
            ) as DataTableValueArray
        )
      );
    }
  }, [rows]);

  const notes = [
    {
      note: 'Acronyms',
      description:
        'PM = Potential incidence of disease due to particulate matter emissions; IRP = Potential human exposure efficiency relative to U235; ETP-fw = Potential comparative toxic unit for ecosystems; HTP-c = Potential comparative toxic unit for humans; HTP-nc = Potential comparative toxic unit for humans; SQP = Potential soil quality index.',
    },
    {
      note: (
        <>
          Disclaimer<sup>1</sup>
        </>
      ),
      description:
        'This impact category deals mainly with the eventual impact of low dose ionizing radiation on human health of the nuclear fuel cycle. It does not consider effects due to possible nuclear accidents, occupational exposure nor due to radioactive waste disposal in underground facilities. Potential ionizing radiation from the soil, from radon and from some construction materials is also not measured by this indicator.',
    },
    {
      note: (
        <>
          Disclaimer<sup>2</sup>
        </>
      ),
      description:
        'The results of this environmental impact indicator shall be used with care as the uncertainties of these results are high or as there is limited experience with the indicator.',
    },
  ];

  if (isAdditionalDisclaimerVisible) {
    notes.push({
      note: (
        <>
          Disclaimer<sup>3</sup>
        </>
      ),
      description:
        'The results of the impact categories land use, human toxicity (cancer), human toxicity, noncancer and ecotoxicity (freshwater) may be highly uncertain in LCAs that include capital goods/infrastructure in generic datasets, in case infrastructure/capital goods contribute greatly to the total results. This is because the LCI data of infrastructure/capital goods used to quantify these indicators in currently available generic datasets sometimes lack temporal, technological and geographical representativeness. Caution should be exercised when using the results of these indicators for decision-making purposes.',
    });
  }

  return <LcaResultsGridPdf value={formattedRows} columns={LcaResultsGridColumnsPdf} notes={notes} />;
};

export default LcaResultsGridAdditionalConstructionPdf;
