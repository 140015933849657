import { TabPanel } from 'primereact/tabview';
import React from 'react';
import { EPDModel, FileLoadingModel } from 'services/EpdClient';
import { TabViewStyled } from 'styles/v2/Styles.styled';
import { EPDGeneralInformationModel } from 'types/types';

import ContentDeclarationTab from './ContentDeclarationTab';
import EPDDocumentsTab from './EPDDocumentsTab';
import ProductInformationTab from './ProductInformationTab';
import TechnicalPerformanceTab from './TechnicalPerformanceTab';

const Step2: React.FunctionComponent<{
  epd?: EPDModel;
  onChangeEpd: any;
  generalInformation: EPDGeneralInformationModel;
  validationState: any;
  fileLoading?: FileLoadingModel[];
}> = ({ epd, onChangeEpd, generalInformation, validationState, fileLoading }) => {
  return (
    <TabViewStyled>
      <TabPanel header="EPD documents">
        <EPDDocumentsTab
          epd={epd}
          epdVersionId={epd?.versionId!}
          onChangeEpd={onChangeEpd}
          canUpload={true}
          isReadOnly={false}
        />
      </TabPanel>
      <TabPanel header="Product declaration">
        <ProductInformationTab
          epd={epd}
          epdVersionId={epd?.versionId!}
          epdClassification={generalInformation?.epdClassification}
          validationState={validationState}
        />
      </TabPanel>
      <TabPanel header="Technical performance">
        <TechnicalPerformanceTab epdVersionId={epd?.versionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Content declaration">
        <ContentDeclarationTab
          epd={epd}
          epdVersionId={epd?.versionId!}
          generalInformation={generalInformation}
          validationState={validationState}
        />
      </TabPanel>
    </TabViewStyled>
  );
};

export default Step2;
