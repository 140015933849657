import { Column, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useState } from 'react';
import {
  useCreateLcaCustomResultGridDataRow,
  useDeleteLcaCustomResultGridDataRow,
  useUpdateLcaCustomResultGridDataRow,
} from 'services/api/mutationsLcaResults';
import { useEpdLcaCustomResultGridData } from 'services/api/queries';
import { MaximizedDialog } from 'styles/v2/Styles.styled';
import { LcaResultModel } from 'types/types';

import { ColumnFooterInputText, DataTableStyled, GridButton, GridButtonGreen } from '../../StyledGrid';
import { LcaResultsAdditionalGridColumns } from '../LcaResultsGridColumns';
import DynamicGridHeader from '../../DynamicGridHeader';
import useGridRowSelectionViaCheckbox from '../../useGridRowSelectionViaCheckbox';
import useIsReadOnlyMode from 'components/v2/compiler/hooks/useIsReadOnlyMode';

type TProps = {
  customResultId: string;
};

const LcaCustomResultsGrid = ({ customResultId }: TProps) => {
  const [visible, setVisible] = useState(false);

  const notes = [
    {
      note: 'Acronyms',
      description: '',
      placeholder: 'Acronym1 description; Acronym2  - description; etc.',
    },
  ];

  const columns = LcaResultsAdditionalGridColumns;
  const rows = useEpdLcaCustomResultGridData(customResultId).data;
  const createMutation = useCreateLcaCustomResultGridDataRow(customResultId);
  const updateMutation = useUpdateLcaCustomResultGridDataRow(customResultId);
  const deleteMutation = useDeleteLcaCustomResultGridDataRow(customResultId);

  const isReadOnly = useIsReadOnlyMode();
  const { selectedRows, setSelectedRows, dataTableProps, checkboxColumnNode } =
    useGridRowSelectionViaCheckbox<LcaResultModel>(isReadOnly);

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const handleAddRow = () => {
    createMutation.mutate({ customResultId });
  };

  const handleDeleteSelected = async () => {
    if (selectedRows.length === 0) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows([]);
  };

  const header = (
    <DynamicGridHeader
      caption="Indicators data"
      onAddRow={handleAddRow}
      onMaximize={visible ? undefined : () => setVisible(true)}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={selectedRows.length === 0 || deleteMutation.isPending}
    />
  );

  const footerGroup = notes ? (
    <ColumnGroup>
      {notes.map((x, i) => (
        <Row key={i}>
          <Column footer={x.note} colSpan={2} className="p-row-note" />
          <Column
            style={{ padding: '2px' }}
            footer={
              <ColumnFooterInputText
                type="text"
                defaultValue={x.description as string}
                onBlur={(e) => {
                  // TODO save the value
                }}
                disabled={isReadOnly}
              />
            }
            colSpan={columns?.length}
            className={isReadOnly ? 'p-row-note' : undefined}
          />
        </Row>
      ))}
    </ColumnGroup>
  ) : null;

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  return (
    <>
      {visible /* this is done intentionally despite dialog has its visibility - otherwise issue with page scrollbar or full screen size of the dialog*/ && (
        <MaximizedDialog header={''} visible={visible} maximizable={true} maximized={true} onHide={() => setVisible(false)}>
          <DataTableStyled
            value={rows}
            dataKey="id"
            tableStyle={{ minWidth: '49rem' }}
            showGridlines
            footerColumnGroup={footerGroup}
            header={header}
            editMode="cell"
            rowClassName={rowClassName}
            {...dataTableProps}
          >
            {checkboxColumnNode}

            {columns?.map((x) => (
              <Column
                key={x.field}
                {...x}
                onCellEditComplete={x.editor ? onCellEditComplete : undefined}
                {...(isReadOnly && { editor: undefined })}
              />
            ))}
          </DataTableStyled>
        </MaximizedDialog>
      )}

      <DataTableStyled
        value={rows}
        dataKey="id"
        tableStyle={{ minWidth: '49rem' }}
        showGridlines
        footerColumnGroup={footerGroup}
        header={header}
        editMode="cell"
        rowClassName={rowClassName}
        {...dataTableProps}
      >
        {checkboxColumnNode}

        {columns?.map((x) => (
          <Column
            key={x.field}
            {...x}
            onCellEditComplete={x.editor ? onCellEditComplete : undefined}
            {...(isReadOnly && { editor: undefined })}
          />
        ))}
      </DataTableStyled>
    </>
  );
};

export default LcaCustomResultsGrid;
