import { REMOVE_FILE } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ActionState, EPDModel, FileLoadingModel } from 'services/EpdClient';
import EpdService from 'services/EpdService';
import { useUpdateEpdData } from 'services/api/mutations';
import {
  useEpd,
  useEpdActionsStates,
  useEpdFormat,
  useEpdGeneralInformation,
  useServerValidationQuery,
} from 'services/api/queries';
import { EPDGeneralInformationModel } from 'types/types';

import CompilerStepsValidator from './CompilerStepsValidator';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { IsReadOnlyModeContext } from './hooks/useIsReadOnlyMode';

const compilerStepsValidator = new CompilerStepsValidator()
  .registerStep(1, (step) => {
    step.withFields(
      'generalInformation',
      // EPD Definition
      'title',
      'declaredStandards',
      'productImages',
      'useAdvice',
      'subtype',
      'epdClassification',
      'representativeJustification',
      'determinationOfAverageDescription',
      'listOfContributingManufacturers',
      'sitesSelectionDescription',
      'documentCoverageStatement',
      'productToDeclare',
      // PCR, LCA, EPD verification
      'pcr',
      'cpcrProductCategory',
      'epdDevelopers',
      'verificationType',
      'thirdPartyVerification',
      'processCertificateReports',
      'preverifiedTool',
      'preverifiedToolVersion',
      'epdVerifier',
      // Organization information
      'companyLogoImages',
      'organizationInformation',
      'organizationInformationAddress'
    );
  })
  .registerStep(2, (step) => {
    step.withFields(
      'products',
      /^products\[\d+\]$/,
      // Content declaration
      'serviceIncludesProductInSystemBoundary',
      'declarationIrrelevanceExplanation',
      'averageProductComponents',
      'averagePackagingMaterials',
      'averagePanelContents',
      'averageDangerMaterials',
      'comparisonWorstAndBestCase'
    );
  })
  .registerStep(3, (step) => {
    step.withFields(
      // Specification
      'infrastructureAndCapitalGoods',
      'dataQualityAssessment',
      'lcaSpecification',
      'productFlow',
      'productFlowName',
      'productFlowUnit',
      'productFlowValue',
      'conversionFactor',
      'conversionFactorUnit',
      'conversionFactorValue',
      'epdDataSource',
      /^epdDataSource\[\d+\]$/,
      'electricity',
      'lcaSoftwareVersion',
      'lcaSoftware',
      'softwareVersion',
      'softwareName',
      'characterisationMethods',
      'technologyDescription',
      'scrapInputs',
      // System boundary
      'systemBoundaryDescription',
      'hasExcludedModules',
      'excludedModulesJustification',
      'declaredModulesErrors',
      // Scenarios
      'scenarios',
      // Results
      'lcaResults',
      'cO2Carbonation',
      'declarationOfCO2Assumptions',
      'transportationPerformance'
    );
  })
  .registerStep(4, (step) => {
    step.withFields('verificationReports', 'validityDate', 'approvalDate');
  })
  .registerStep(5, (step) => {
    step.withFields('registrationDate', 'publicVersionDate', 'serviceAgreementStatus');
  });

const useCompilerLogic = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companyAccountId, company } = React.useContext(CompanyContext);
  const [isExplanationVisible, setIsExplanationVisible] = useState<boolean>(true);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(1);
  const helpButtonRef = useRef<HTMLButtonElement | undefined>(null);

  const [isUiBlocked, setIsUiBlocked] = useState<boolean>(false);
  const [isUpdatingEpd, setIsUpdatingEpd] = useState<boolean>(false);
  const [declinePublicationText, setDeclinePublicationText] = useState<string>('');
  const [fileLoading, setFileLoading] = useState<FileLoadingModel[]>([]);
  const { id } = useParams<any>();

  const epdQuery = useEpd(id);
  const epdFormatQuery = useEpdFormat(id);
  const actionsStates = useEpdActionsStates(id).data;
  const epdGeneralInformationQuery = useEpdGeneralInformation(epdQuery.data?.versionId!);
  const serverValidationQuery = useServerValidationQuery(epdQuery.data?.versionId!);
  const serverValidationResult = serverValidationQuery?.data;

  const updateEpdData = useUpdateEpdData(id);

  const onChangeEpd = async (propertyName: string, val: any) => {
    addEpdProperty(epdQuery.data?.id!, epdQuery.data?.versionId!, propertyName, val);
  };

  const addEpdProperty = async (epdId: string, versionId: string, propertyName: string, val: any) => {
    try {
      setIsUpdatingEpd(true);

      if (propertyName === REMOVE_FILE) {
        await EpdService.removeEpdFile(val, versionId);
      } else {
        updateEpdData.mutate({
          epdId: epdId,
          versionId: epdQuery.data?.versionId,
          propertyName: propertyName,
          newValue: val,
        });
      }
    } catch {
    } finally {
      setIsUpdatingEpd(false);
    }
  };

  const changeOrAddFileLoading = (type: string, newLoad: boolean, prev: FileLoadingModel[]) => {
    if (prev.find((model) => model.fileType === type)) {
      return prev.map((model) =>
        model.fileType === type
          ? { ...model, loadingsCount: newLoad ? model.loadingsCount + 1 : model.loadingsCount - 1 }
          : model
      );
    } else {
      return [...prev, { fileType: type, loadingsCount: 1 }];
    }
  };

  type StepDefinition = {
    label: string;
    name: string;
    description: string;
    id: number;
    render: () => JSX.Element;
    isValid: (item: any) => boolean | undefined;
  };

  const isMainContentReadOnly = serverValidationResult?.fieldsState?.mainContent !== ActionState.Enabled;
  const steps: StepDefinition[] = [
    {
      label: 'Step 1',
      name: 'General information',
      description: 'Fill-in the EPD initial data',
      id: 1,
      render: () => {
        return (
          <IsReadOnlyModeContext.Provider value={isMainContentReadOnly}>
            <Step1 epd={epdQuery.data as EPDModel} validationState={serverValidationQuery.data} />
          </IsReadOnlyModeContext.Provider>
        );
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(1, errors),
    },
    {
      label: 'Step 2',
      name: 'Product declaration',
      description: 'Describe your EPD subject',
      id: 2,
      render: () => {
        return (
          <IsReadOnlyModeContext.Provider value={isMainContentReadOnly}>
            <Step2
              epd={epdQuery.data}
              onChangeEpd={onChangeEpd}
              fileLoading={fileLoading}
              generalInformation={epdGeneralInformationQuery.data as EPDGeneralInformationModel}
              validationState={serverValidationQuery.data}
            />
          </IsReadOnlyModeContext.Provider>
        );
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(2, errors),
    },
    {
      label: 'Step 3',
      name: 'LCA information',
      description: 'Describe your LCA output',
      id: 3,
      render: () => {
        return (
          <IsReadOnlyModeContext.Provider value={isMainContentReadOnly}>
            <Step3
              epd={epdQuery.data}
              onChangeEpd={onChangeEpd}
              fileLoading={fileLoading}
              validationState={serverValidationQuery.data}
            />
          </IsReadOnlyModeContext.Provider>
        );
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(3, errors),
    },
    {
      label: 'Step 4',
      name: 'EPD verification',
      description: 'Get the third-party approval',
      id: 4,
      render: () => {
        return (
          <Step4
            epdId={epdQuery.data?.id!}
            epdVersionId={epdQuery.data?.versionId!}
            validationState={serverValidationQuery.data}
          />
        );
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(4, errors),
    },
    {
      label: 'Step 5',
      name: 'EPD publication',
      description: 'Submit the EPD for publication',
      id: 5,
      render: () => {
        return <Step5 epd={epdQuery.data} onChangeEpd={onChangeEpd} validationState={serverValidationQuery.data} />;
      },
      isValid: (errors) => compilerStepsValidator.isStepValid(5, errors),
    },
    {
      label: 'Step 6',
      name: 'Generated EPD',
      description: 'Get your EPD in PDF format',
      id: 6,
      render: () => {
        return (
          <Step6
            epd={epdQuery.data as EPDModel}
            generalInformation={epdGeneralInformationQuery.data as EPDGeneralInformationModel}
            onChangeEpd={onChangeEpd}
          />
        );
      },
      isValid: () => undefined,
    },
  ];

  return {
    companyAccountId,
    company,
    t,
    history,
    steps,
    activeStepIndex,
    setActiveStepIndex,
    helpButtonRef,

    isExplanationVisible,
    setIsExplanationVisible,
    declinePublicationText,
    setDeclinePublicationText,
    isUiBlocked,
    setIsUiBlocked,
    isUpdatingEpd,
    epdQuery,
    epdGeneralInformationQuery,
    epdFormatQuery,
    actionsStates,
    serverValidationResult,
  };
};

export default useCompilerLogic;
