import { customSelectStyles } from 'components/form/Select';
import { ProcessStatus } from 'constants/constants';
import filterIcon from 'images/icons/svg/funnel.svg';
import _ from 'lodash';
import { SpanLink } from 'pages/wizard/EpdWizard';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ActionState, EPDModel, PCRModel, ProductCategoryModel } from 'services/EpdClient';
import FileService from 'services/FileService';
import PcrService from 'services/PcrService';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import { A, InputSmallIcon, Text } from 'styles/Styles.styled';
import { Theme } from 'styles/theme';

interface PcrProps {
  epd: EPDModel;
  onChangePcr: (pcr: PCRModel | undefined) => void;
  onRemovePcr: () => void;
}

const PcrSelector: React.FunctionComponent<PcrProps> = ({ epd, onChangePcr, onRemovePcr }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState<string>();
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [productCategory, setProductCategory] = React.useState<{ value: string; label: string }>();
  const [pcrList, setPcrList] = React.useState<PCRModel[]>([]);
  const [productCategoryList, setProductCategoryList] = React.useState<ProductCategoryModel[]>([]);
  const [showFiles, setShowFiles] = React.useState<boolean>(false);
  const theme = useTheme() as Theme;

  const findPcr = async (val: string, catId?: string) => {
    setStatus(ProcessStatus.Fetching);
    const res = await PcrService.findPcr(val || '', undefined, catId);
    setPcrList(res);
    setStatus(ProcessStatus.Success);
  };

  const debounceLoadData = useCallback(_.debounce(findPcr, 500), []);

  React.useEffect(() => {
    const fetchPcr = async () => {
      if (!productCategory && (filter?.length ?? 0) < 2) {
        setPcrList([]);
        return;
      }

      debounceLoadData(filter || '', productCategory?.value);
    };

    fetchPcr();
  }, [filter, productCategory, debounceLoadData]);

  React.useEffect(() => {
    const fetchCats = async () => {
      const res = await PcrService.getAllCategories();
      setProductCategoryList(res);
    };

    fetchCats();
  }, []);

  const handleFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const selectPcr = (selectedPcr: PCRModel | undefined) => {
    onChangePcr(selectedPcr);
    setFilter(undefined);
    setShowFiles(false);
  };

  const productCategoryListOptions: any = () => {
    const list = productCategoryList.map((item) => {
      return {
        value: item.id,
        label: item.title,
      };
    });

    return list;
  };

  const hasDocuments = epd?.pcr?.documents && epd?.pcr?.documents?.length > 0;
  const hasMultipleDocuments = epd?.pcr?.documents && epd?.pcr?.documents?.length > 1;

  return (
    <div style={{ flexGrow: 1 }}>
      {epd.pcr ? (
        <div style={{ margin: '1rem 0' }}>
          <strong style={{ display: 'block' }}>{epd.pcr.shortName}</strong>{' '}
          {epd?.pcrState.actionState === ActionState.Enabled ? (
            <SpanLink onClick={onRemovePcr}>{t('remove')}</SpanLink>
          ) : epd?.pcrState.actionState === ActionState.Disabled ? (
            <span style={{ color: 'gray', cursor: 'not-allowed' }}>{t('remove')}</span>
          ) : (
            <span></span>
          )}
          {hasDocuments && !showFiles && (
            <>
              {' | '}{' '}
              <SpanLink onClick={() => setShowFiles(true)}>{t('epdWizard.wizardStep1.pcrMenu.downloadPcr')}</SpanLink>
            </>
          )}
          {showFiles && (
            <>
              <div style={{ padding: '1rem', backgroundColor: theme.colors.regionColorLight, marginTop: '1rem' }}>
                <Text style={{ fontWeight: 'bold', marginTop: 0 }}>
                  {t('pcrDocument')}
                  {hasMultipleDocuments && t('s')}
                </Text>
                {epd?.pcr?.documents?.map(
                  (file, index) =>
                    file && (
                      <div key={index}>
                        <A href={FileService.getImageUrl(file?.id || '')}>
                          {file?.name || t('epdWizard.wizardStep1.pcrMenu.placeholderImage')}
                        </A>
                      </div>
                    )
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '1 0 auto' }}>
              <InputSmallIcon
                disabled={epd?.pcrState.actionState === ActionState.Disabled}
                style={{ backgroundImage: `url(${filterIcon})`, width: '-webkit-fill-available' }}
                onChange={handleFilter}
                value={filter || ''}
                placeholder={t('epdWizard.wizardStep1.epdDeveloperMenu.placeholder.filterOnNameOrCode')}
              />
            </div>
          </div>
          <Select
            isClearable={true}
            isDisabled={epd?.pcrState.actionState === ActionState.Disabled}
            value={productCategory}
            onChange={(value: any) => {
              setProductCategory(value);
            }}
            placeholder={t('epdWizard.wizardStep1.epdDeveloperMenu.placeholder.productCategory')}
            styles={customSelectStyles}
            options={productCategoryListOptions()}
          />

          <div style={{ border: 'solid 1px #f3f3f3', margin: '0 0 1rem 0' }}>
            {status === ProcessStatus.Fetching && (
              <div style={{ padding: '1rem', fontStyle: 'italic' }}>
                {t('epdWizard.wizardStep1.pcrMenu.messages.fetchList')}
              </div>
            )}
            {status === ProcessStatus.Success &&
              (pcrList?.length > 0 ? (
                pcrList.map((item) => (
                  <PcrListItem
                    onClick={epd?.pcrState.actionState === ActionState.Disabled ? () => {} : () => selectPcr(item)}
                    title={t('epdWizard.wizardStep1.pcrMenu.messages.selectPcr')}
                    key={item.id}
                  >
                    <div style={{ fontWeight: 'bold' }}>{item.fullName}</div>
                    {item.cpCs && (
                      <div>
                        {item.cpCs.map((cpc) => (
                          <div style={{ padding: '0.5rem 0 0 0 ' }} key={cpc.id}>
                            <em>{cpc.code}</em> <span>{cpc.description}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </PcrListItem>
                ))
              ) : (filter?.length ?? 0) < 2 ? (
                <div style={{ padding: '1rem', fontStyle: 'italic' }}>
                  {t('epdWizard.wizardStep1.pcrMenu.messages.selectOther')}
                </div>
              ) : (
                <div style={{ padding: '1rem', fontStyle: 'italic' }}>
                  {t('epdWizard.wizardStep1.pcrMenu.messages.noMatches')}
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const PcrListItem = styled.div`
  padding: 1rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  &:nth-child(even) {
    background-color: #f3f3f3;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.regionColorLight};
  }
`;

export default PcrSelector;
