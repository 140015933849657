export const YES_NO = {
  YES: 'Yes' as const,
  NO: 'No' as const,
};

export const YesNoDataSource = [
  { value: YES_NO.YES, label: YES_NO.YES },
  { value: YES_NO.NO, label: YES_NO.NO },
];

export const ISO_21930_ID = '3';

export const SINGLE_PRODUCT_ID = 1;
export const MULTIPLE_PRODUCTS_BASED_ON_AVG_RESULTS_ID = 2;
export const MULTIPLE_PRODUCTS_BASED_ON_REPRESENTATIVE_PRODUCT_ID = 3;
export const MULTIPLE_PRODUCTS_BASED_ON_WORST_CASE_RESULTS_ID = 4;
export const SECTOR_OF_MULTIPLE_PRODUCTS_ID = 5;