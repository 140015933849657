import companyLogo from 'assets/images/dashboard.png';
import ErrorFallback from 'components/error-fallback/ErrorFallback';
import useCompilerPdfLogic from 'components/v2/compiler/useCompilerPdfLogic';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import AuthService from 'services/AuthService';
import { CountryModel } from 'services/EpdClient';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import styled from 'styled-components';
import { EPDDeclareContentProductModel, EPDGeneralInformationModel, EPDPDFModel, Option, PCRTypeModel } from 'types/types';
import { formatDate, getOptionsFromDictionary } from 'util/utils';

import Page_AdditionalInfo from './AdditionalInfoPage';
import Page6_ContentDeclaration from './ContentDeclarationPage';
import CoverPage from './CoverPage';
import LCACustomResultsPage from './LCACustomResultsPage';
import Page7_9_LCAInformation from './LCAInformationPage';
import Page17_19_LCAResults from './LCAResultsPage';
import Page4_ManufacturerInformation from './ManufacturerInformationPage';
import Page3_Ownership from './OwnershipPage';
import Page11_ProcessFlowDescription from './ProcessFlowDescriptionPage';
import Page5_ProductInformation from './ProductInformationPage';
import Page2_ProgrammeInformation from './ProgrammeInformationPage';
import Page_References from './ReferencePage';
import Page12_17_Scenarios from './ScenariosPage';
import Page10_SystemBoundary from './SystemBoundaryPage';
import TechPerformancePage from './TechPerformancePage';

const EpdCompilerPdfPreviewAuth: React.FunctionComponent = () => {
  const {
    epdGeneralInformationQuery,
    additionalInformationQuery,
    organizationInformationQuery,
    organizationInformationAddressQuery,
    productInformationQuery,
    dictionariesQuery,
    countriesQuery,
    coverPageInformationQuery,
    lcaResultsQuery,
    lcaCustomResultsQuery,
    pcrInformationQuery,
    infrastructureAndCapitalGoodsQuery,
    lcaSpecificationQuery,
    dataSourcesQuery,
    contentDeclarationProductsQuery,
    epdScenariosQuery,
  } = useCompilerPdfLogic();

  const epdInformation =
    epdGeneralInformationQuery?.data ?? ({ declarationName: null } as unknown as EPDGeneralInformationModel);
  const additionalInformation = additionalInformationQuery?.data;
  const organizationInformation = organizationInformationQuery.data;
  const organizationInformationAddress = organizationInformationAddressQuery.data;
  const productInformations = productInformationQuery.data;
  const countriesList = countriesQuery.data;
  const coverPageInformation = coverPageInformationQuery.data;
  const lcaResultsRows = lcaResultsQuery.data;
  const pcrInformation = pcrInformationQuery.data;
  const infrastructureAndCapitalGoods = infrastructureAndCapitalGoodsQuery.data;
  const lcaSpecification = lcaSpecificationQuery.data;
  const dataSources = dataSourcesQuery.data;
  const contentDeclarationProducts = contentDeclarationProductsQuery.data;
  const referenceFlowUnits = getOptionsFromDictionary(dictionariesQuery.data, EPD_DICTIONARY_CODES.REFERENCE_FLOW_UNIT);
  const lcaCustomResults = lcaCustomResultsQuery.data || [];
  const additionalPerformanceResultsTypeLookup = getOptionsFromDictionary(
    dictionariesQuery.data,
    EPD_DICTIONARY_CODES.ADDITIONAL_LCA_RESULTS_TYPE
  );
  const getOptions = (code: string) => getOptionsFromDictionary(dictionariesQuery.data, code);
  const scenarios = epdScenariosQuery.data || [];

  const epdPDFModel: EPDPDFModel = {
    generalInformation: {
      epdVersionId: epdInformation.epdVersionId,
      declarationName: epdInformation.declarationName ?? 'Some name',
      registrationNumber: epdInformation.registrationNumber,
      publishDate: epdInformation.issueDate,
      serviceIncludesProductInSystemBoundary: epdInformation.serviceIncludesProductInSystemBoundary,
      declarationIrrelevanceExplanation: epdInformation.declarationIrrelevanceExplanation,
      expirationDate: formatDate(epdInformation.expirationDate),
      revisionDate: formatDate(new Date(Date.now())),
      epdClassification: epdInformation.epdClassification,
      documentCoverageStatement: epdInformation.documentCoverageStatement,
    },
    products: productInformations,
    organizationInformation: { ...(organizationInformation as any), address: organizationInformationAddress },
    companyInformation: {
      name: 'Company name',
      logo: companyLogo,
      address: 'Company address',
      description: 'Company description',
      contact: 'Contact person information',
    },
    additionalInformation: { ...(additionalInformation as any) },
    pcrInformation: { ...(pcrInformation as PCRTypeModel) },
    coverPageInformation: { ...(coverPageInformation as any) },
    countries: countriesList,
    infrastructureAndCapitalGoods: { ...(infrastructureAndCapitalGoods as any) },
    lcaSpecification: { ...(lcaSpecification as any) },
    contentDeclarationProducts: contentDeclarationProducts as EPDDeclareContentProductModel[],
    scenarios: scenarios,
  };

  return (
    <>
      {AuthService.isLoggedin() && (
        <>
          <CoverPage e={epdPDFModel} getOptionsFromDictionary={getOptions} />
          <Page2_ProgrammeInformation e={epdPDFModel} />
          <Page3_Ownership e={epdPDFModel} />
          <Page4_ManufacturerInformation e={epdPDFModel} />
          {productInformations?.map((product, index) => (
            <React.Fragment key={index}>
              <Page5_ProductInformation
                e={epdPDFModel}
                productInformation={product}
                countries={countriesList as CountryModel[]}
                getOptionsFromDictionary={getOptions}
              />
            </React.Fragment>
          ))}

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <TechPerformancePage epdVersionId={epdInformation.epdVersionId} />
          </ErrorBoundary>

          <Page6_ContentDeclaration e={epdPDFModel} />
          <Page7_9_LCAInformation
            e={epdPDFModel}
            dataSources={dataSources}
            getOptionsFromDictionary={getOptions}
            referenceFlowUnits={referenceFlowUnits}
          />
          <Page10_SystemBoundary e={epdPDFModel} />
          <Page11_ProcessFlowDescription e={epdPDFModel} />

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Page12_17_Scenarios epd={epdPDFModel} />
          </ErrorBoundary>

          <Page17_19_LCAResults
            infrastructureAndCapitalGoods={infrastructureAndCapitalGoods}
            lcaResults={lcaResultsRows?.lcaResults}
          />

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <LCACustomResultsPage
              lcaCustomResults={lcaCustomResults}
              additionalPerformanceResultsTypeLookup={additionalPerformanceResultsTypeLookup}
              scenariosLookup={scenarios.map((s) => {
                return {
                  value: s.id,
                  label: s.name,
                } as Option;
              })}
            />
          </ErrorBoundary>

          <Page_AdditionalInfo e={epdPDFModel} />
          <Page_References e={epdPDFModel} />
          <PDF_PAGE className="final">
            <div></div>
            <div style={{ textAlign: 'center', padding: '1cm' }}>environdec.com</div>
          </PDF_PAGE>
        </>
      )}
    </>
  );
};

const PDF_PAGE = styled.div`
  width: 100%;
  page-break-before: always;
  background-color: rgba(180, 208, 182, 0.3);
`;

export default EpdCompilerPdfPreviewAuth;
