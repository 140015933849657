import { CompanyContext } from 'contexts/CompanyContextProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useCountriesLookup,
  useDictionaryValues,
  useEpdAdditionalInformation,
  useEpdContentDeclarationProducts,
  useEpdCoverPageInformation,
  useEpdDataSources,
  useEpdGeneralInformation,
  useEpdLcaCustomResults,
  useEpdLcaResults,
  useEpdOrganizationInformation,
  useEpdOrganizationInformationAddress,
  useEpdProductInformation,
  useEpdScenarios,
  useGetEpdPCRInformation,
  useGetInfrastructureAndCapitalGoods,
  useGetLCASpecification,
} from 'services/api/queries';

const useCompilerPdfLogic = () => {
  const { t } = useTranslation();
  const { companyAccountId, company } = React.useContext(CompanyContext);
  const { id } = useParams<any>();
  const epdGeneralInformationQuery = useEpdGeneralInformation(id);
  const additionalInformationQuery = useEpdAdditionalInformation(id);
  const organizationInformationQuery = useEpdOrganizationInformation(id);
  const organizationInformationAddressQuery = useEpdOrganizationInformationAddress(id);
  const productInformationQuery = useEpdProductInformation(id);
  const countriesQuery = useCountriesLookup();
  const dictionariesQuery = useDictionaryValues();
  const coverPageInformationQuery = useEpdCoverPageInformation(id);
  const lcaResultsQuery = useEpdLcaResults(id!);
  const lcaCustomResultsQuery = useEpdLcaCustomResults(id!);
  const pcrInformationQuery = useGetEpdPCRInformation(id);
  const infrastructureAndCapitalGoodsQuery = useGetInfrastructureAndCapitalGoods(id);
  const lcaSpecificationQuery = useGetLCASpecification(id!);
  const dataSourcesQuery = useEpdDataSources(id!);
  const contentDeclarationProductsQuery = useEpdContentDeclarationProducts(id);
  const epdScenariosQuery = useEpdScenarios(id);

  return {
    companyAccountId,
    company,
    t,
    epdGeneralInformationQuery,
    additionalInformationQuery,
    organizationInformationQuery,
    organizationInformationAddressQuery,
    productInformationQuery,
    dictionariesQuery,
    countriesQuery,
    coverPageInformationQuery,
    lcaResultsQuery,
    lcaCustomResultsQuery,
    pcrInformationQuery,
    infrastructureAndCapitalGoodsQuery,
    lcaSpecificationQuery,
    dataSourcesQuery,
    contentDeclarationProductsQuery,
    epdScenariosQuery,
  };
};

export default useCompilerPdfLogic;
